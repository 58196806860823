import {
  type CustomReportColumn,
  type PayoutAndPerformanceChartStatistics,
} from '@amalia/reporting/custom-reports/shared';

/**
 * Kpi columns are all columns except the payment value and the achievement period.
 * @param statistics
 */
export const findPayoutAndPerformanceChartStatisticsKpiColumns = (
  statistics?: PayoutAndPerformanceChartStatistics,
): CustomReportColumn[] =>
  Object.values(statistics?.definitions || {}).filter(
    (definition) =>
      ![
        statistics!.definitions.ruleMetricPayment__value?.identifier,
        statistics!.definitions.ruleMetricPeriod__month?.identifier,
        statistics!.definitions.RULE_METRIC__statementId.identifier,
      ]
        .filter(Boolean)
        .includes(definition.identifier),
  );
