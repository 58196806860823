import { UTCDateMini } from '@date-fns/utc';
import { useCallback, useMemo } from 'react';

import { type DatePickerBaseProps } from '../DatePickerBase';

const toUtcDate = (date?: Date | UTCDateMini | null) =>
  date
    ? date instanceof UTCDateMini
      ? date
      : new UTCDateMini(date.getFullYear(), date.getMonth(), date.getDate())
    : null;

const maybeToUtcDate = (date?: Date | UTCDateMini | null, enabled?: boolean) =>
  enabled ? toUtcDate(date) : (date ?? null);

const valueToMaybeUtcDate = <TWithRange extends boolean | undefined = undefined>(
  value: DatePickerBaseProps<TWithRange>['value'],
  selectsRange?: TWithRange,
  enabled?: boolean,
) =>
  (selectsRange
    ? [
        maybeToUtcDate((value as DatePickerBaseProps<true>['value'])[0], enabled),
        maybeToUtcDate((value as DatePickerBaseProps<true>['value'])[1], enabled),
      ]
    : maybeToUtcDate(
        value as DatePickerBaseProps<false>['value'],
        enabled,
      )) as DatePickerBaseProps<TWithRange>['value'];

/**
 * Convert all dates to utc, and call onChange with utc dates.
 */
export const useUtcDatePickerAdapter = <TWithRange extends boolean | undefined = undefined>(
  {
    value,
    onChange,
    minDate,
    maxDate,
    selectsRange,
  }: Pick<DatePickerBaseProps<TWithRange>, 'maxDate' | 'minDate' | 'onChange' | 'selectsRange' | 'value'>,
  /** If false, don't convert anything. */
  enabled?: boolean,
) => {
  const utcMinDate = useMemo(() => maybeToUtcDate(minDate, enabled), [enabled, minDate]);
  const utcMaxDate = useMemo(() => maybeToUtcDate(maxDate, enabled), [enabled, maxDate]);
  const utcValue = useMemo(() => valueToMaybeUtcDate(value, selectsRange, enabled), [enabled, selectsRange, value]);

  const handleChangeUtc: NonNullable<typeof onChange> = useCallback(
    (newDate) => onChange?.(valueToMaybeUtcDate<TWithRange>(newDate, selectsRange, enabled)),
    [enabled, onChange, selectsRange],
  );

  return {
    maxDate: utcMaxDate,
    minDate: utcMinDate,
    onChange: handleChangeUtc,
    value: utcValue,
  };
};
