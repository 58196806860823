import { useCallback, useMemo } from 'react';

import {
  type FieldVariable,
  type Quota,
  type StatementVariable,
  TokenType,
  VariableType,
} from '@amalia/amalia-lang/tokens/types';
import { type Property } from '@amalia/data-capture/fields/types';
import {
  useRealCustomObjectDefinitions,
  useVirtualCustomObjectDefinitions,
} from '@amalia/data-capture/record-models/state';
import { type CustomObjectDefinition } from '@amalia/data-capture/record-models/types';
import { useShallowObjectMemo } from '@amalia/ext/react/hooks';
import { type Filter, type Relationship, type Rule } from '@amalia/payout-definition/plans/types';

import { useFilters } from './filters/filters.queries';
import { useRelationships } from './relationships/relationships.queries';
import { useRules } from './rules/rules.queries';
import { useVariables } from './variables/variables.queries';

export interface DesignerLibrary {
  OBJECT_DEFINITIONS: CustomObjectDefinition[];
  VIRTUAL_OBJECT_DEFINITIONS: CustomObjectDefinition[];
  [TokenType.FIELD]: FieldVariable[];
  [TokenType.FILTER]: Filter[];
  [TokenType.LINK]: Relationship[];
  [TokenType.QUOTA]: Quota[];
  [TokenType.RULE]: Rule[];
  [TokenType.VARIABLE]: StatementVariable[];
}

export const useDesignerLibrary = () => {
  const { isLoading: isFiltersLoading, data: filters = [] } = useFilters();
  const { isLoading: isObjectDefinitionsLoading, data: objectDefinitions = [] } = useRealCustomObjectDefinitions();
  const { isLoading: isRelationshipsLoading, data: relationships = [] } = useRelationships();
  const { isLoading: isRulesLoading, data: rules = [] } = useRules();
  const { isLoading: isVariablesLoading, data: variables = [] } = useVariables();
  const { isLoading: isVirtualObjectDefinitionsLoading, data: virtualObjectDefinitions = [] } =
    useVirtualCustomObjectDefinitions();

  const { fields, quotas, statementVariables } = useMemo(
    () => ({
      statementVariables: variables.filter(
        (variable) => variable.type === VariableType.statement,
      ) as StatementVariable[],
      fields: variables.filter((variable) => variable.type === VariableType.object) as FieldVariable[],
      quotas: variables.filter((variable) =>
        [VariableType.team, VariableType.user, VariableType.plan].includes(variable.type),
      ) as Quota[],
    }),
    [variables],
  );

  const tokens: DesignerLibrary = useShallowObjectMemo<DesignerLibrary>({
    OBJECT_DEFINITIONS: objectDefinitions,
    VIRTUAL_OBJECT_DEFINITIONS: virtualObjectDefinitions,
    [TokenType.FIELD]: fields,
    [TokenType.FILTER]: filters,
    [TokenType.QUOTA]: quotas,
    [TokenType.LINK]: relationships,
    [TokenType.RULE]: rules,
    [TokenType.VARIABLE]: statementVariables,
  });

  const allTokenIds = useMemo(
    () => [...variables, ...filters, ...relationships].map((token) => token.id).filter(Boolean),
    [variables, filters, relationships],
  );

  const accessors = useShallowObjectMemo({
    getFilterById: useCallback(
      (id?: Filter['id'] | null): Filter | undefined => filters.find((filter) => filter.id === id),
      [filters],
    ),
    getQuotaById: useCallback(
      (id?: Quota['id'] | null): Quota | undefined => quotas.find((quota) => quota.id === id),
      [quotas],
    ),
    getRuleById: useCallback(
      (id?: Rule['id'] | null): Rule | undefined => rules.find((rule) => rule.id === id),
      [rules],
    ),
    getFieldById: useCallback(
      (id?: FieldVariable['id'] | null): FieldVariable | undefined => fields.find((field) => field.id === id),
      [fields],
    ),
    getStatementVariableById: useCallback(
      (id?: StatementVariable['id'] | null): StatementVariable | undefined =>
        statementVariables.find((statementVariable) => statementVariable.id === id),
      [statementVariables],
    ),
    getPropertyByIdentifier: useCallback(
      (identifier?: string): { definition: CustomObjectDefinition; property: Property } | undefined => {
        if (!identifier) {
          return undefined;
        }

        const [recordModelMachineName, propertyName] = identifier.split('.');

        const foundDefinition = [...tokens.OBJECT_DEFINITIONS, ...tokens.VIRTUAL_OBJECT_DEFINITIONS].find(
          (d) => d.machineName === recordModelMachineName,
        );

        if (!foundDefinition) {
          return undefined;
        }

        const foundProperty = foundDefinition.properties[propertyName];

        if (!foundProperty) {
          return undefined;
        }

        return { property: foundProperty, definition: foundDefinition };
      },
      [tokens.OBJECT_DEFINITIONS, tokens.VIRTUAL_OBJECT_DEFINITIONS],
    ),
  });

  return {
    tokens,
    allTokenIds,
    accessors,
    isLoading: [
      isFiltersLoading,
      isObjectDefinitionsLoading,
      isRelationshipsLoading,
      isRulesLoading,
      isVariablesLoading,
      isVirtualObjectDefinitionsLoading,
    ].some((v) => !!v),
  };
};
