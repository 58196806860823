import { useMemo } from 'react';
import { useIntl } from 'react-intl';

import { type SelectDropdownOption } from '@amalia/design-system/components';
import { relativePeriodKeywordsMessages } from '@amalia/payout-definition/periods/intl';
import { RelativePeriodKeyword, type Period } from '@amalia/payout-definition/periods/types';

import { type PeriodOption } from './PeriodSelect.types';

type AllPeriodOptions = [
  {
    value: 'None';
    label: string;
  },
  {
    label: string;
    options: PeriodOption[];
  },
  {
    label: string;
    options: PeriodOption[] | SelectDropdownOption<string>[];
  },
];

export type UsePeriodOptionsReturnType<TWithNoneOption extends boolean | undefined = true> = TWithNoneOption extends
  | true
  | undefined
  ? AllPeriodOptions
  : [AllPeriodOptions[1], AllPeriodOptions[2]];

type UsePeriodOptionsArgs<TWithNoneOption extends boolean | undefined> = {
  overridesPeriodOptions?: SelectDropdownOption<string>[];
  periodList: Period[];
  withNoneOption?: TWithNoneOption;
  relativePeriodToOmit?: RelativePeriodKeyword[];
};

export const usePeriodOptions = <TWithNoneOption extends boolean | undefined = true>({
  overridesPeriodOptions,
  periodList,
  withNoneOption = true as TWithNoneOption,
  relativePeriodToOmit,
}: UsePeriodOptionsArgs<TWithNoneOption>): UsePeriodOptionsReturnType<TWithNoneOption> => {
  const { formatMessage } = useIntl();

  const noneOptions = useMemo(
    () => ({
      value: 'None' as const,
      label: formatMessage({ defaultMessage: 'None' }),
    }),
    [formatMessage],
  );

  const relativePeriodOptions = useMemo(
    () => ({
      label: formatMessage({ defaultMessage: 'Relative periods' }),
      options: Object.values(RelativePeriodKeyword)
        .filter((keyword) => (relativePeriodToOmit ? !relativePeriodToOmit.includes(keyword) : true))
        .map((keyword) => ({
          value: keyword,
          label: formatMessage(relativePeriodKeywordsMessages[keyword]),
          componentWhenMultiple: 'RADIO',
        })),
    }),
    [formatMessage, relativePeriodToOmit],
  );

  const customPeriodOptions = useMemo(
    () => ({
      label: formatMessage({ defaultMessage: 'Custom periods' }),
      options:
        overridesPeriodOptions ||
        periodList.map((period) => ({
          value: period.id,
          label: period.name,
        })),
    }),
    [formatMessage, overridesPeriodOptions, periodList],
  );

  return useMemo(
    () =>
      (withNoneOption
        ? ([noneOptions, relativePeriodOptions, customPeriodOptions] as UsePeriodOptionsReturnType<true>)
        : ([
            relativePeriodOptions,
            customPeriodOptions,
          ] as UsePeriodOptionsReturnType<false>)) as UsePeriodOptionsReturnType<TWithNoneOption>,
    [noneOptions, relativePeriodOptions, customPeriodOptions, withNoneOption],
  );
};
