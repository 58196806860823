import { useQuery } from '@tanstack/react-query';

import { assert } from '@amalia/ext/typescript';
import { type Period } from '@amalia/payout-definition/periods/types';

import { PaymentApiClient } from './api-client';
import { PAYMENTS_QUERY_KEYS } from './payments.queries.keys';

export const usePaymentsFacets = (periodId?: Period['id']) =>
  useQuery({
    queryKey: [PAYMENTS_QUERY_KEYS.PAYMENTS_FACETS, periodId],
    queryFn: () => {
      assert(periodId, 'periodId is required');
      return PaymentApiClient.getPaymentsFacets({ periodIds: [periodId] });
    },
    enabled: !!periodId,
  });

export const usePayments = (
  args: Parameters<(typeof PaymentApiClient)['getPaymentPaginate']>,
  { enabled = true } = {},
) =>
  useQuery({
    queryKey: [PAYMENTS_QUERY_KEYS.PAYMENTS, ...args],
    queryFn: () => PaymentApiClient.getPaymentPaginate(...args),
    enabled,
  });
