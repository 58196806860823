import { useTheme } from '@emotion/react';
import { merge } from 'lodash';
import { memo, useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import {
  Bar,
  CartesianGrid,
  Cell,
  ComposedChart,
  LabelList,
  Line,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { type CategoricalChartFunc } from 'recharts/types/chart/generateCategoricalChart';
import { type RequiredDeep } from 'type-fest';

import { type CurrencySymbolsEnum } from '@amalia/ext/iso-4217';
import { assert } from '@amalia/ext/typescript';
import { type PeriodFrequencyEnum } from '@amalia/payout-definition/periods/types';
import {
  type PayoutAndPerformanceChartStatisticsRecord,
  type PayoutAndPerformanceChartStatistics,
} from '@amalia/reporting/custom-reports/shared';

import { type ChartSeries } from '../chartSeries';
import { useChartSeries } from '../useChartSeries';

import { BarLabel } from './bar-label/BarLabel';
import { formatChartValue } from './chartValue.formatter';
import { useActiveRecords } from './hooks/useActiveRecords';
import { useAverage } from './hooks/useAverage';
import { useHideKpisForInactiveRecords } from './hooks/useHideKpisForInactiveRecords';
import { useRoundRecordValues } from './hooks/useRoundRecordValues';
import { Tooltip as CustomTooltip } from './tooltip/Tooltip';
import { MonthAxisTick } from './x-axis-months/MonthAxisTick';
import { EMPTY_UPPER_TICK } from './y-axis-amount/amountTickLevels.mapper';
import { useAmountTicks } from './y-axis-amount/useAmountTicks';
import { YAxisTick } from './y-axis-amount/y-axis-tick/YAxisTick';

export type PayoutAndPerformanceChartStyles = {
  bar?: {
    widthSmall?: number;
    widthLarge?: number;
    radiusSmall?: number | [number, number, number, number];
    radiusLarge?: number | [number, number, number, number];
    label?: {
      fontSize?: number;
      fontWeight?: number;
    };
  };
  xAxis?: {
    offset?: number;
    fontSize?: number;
    fontWeight?: number;
  };
  yAxis?: {
    fontSize?: number;
    fontWeight?: number;
  };
};

export type PayoutAndPerformanceChartProps = {
  readonly currency: CurrencySymbolsEnum;
  readonly statistics?: PayoutAndPerformanceChartStatistics;
  readonly hiddenSeries?: ChartSeries['name'][];
  readonly frequency?: PeriodFrequencyEnum;
  /** ComposedChart reads its children as elements to determine what to render so we can't encapsulate elements for styling, so we pass styling overrides in a single prop to avoid polluting the interface. */
  readonly styles?: PayoutAndPerformanceChartStyles;
  readonly isRecordActiveFn?: (data: PayoutAndPerformanceChartStatisticsRecord) => boolean;
  readonly onClickBar?: (data: PayoutAndPerformanceChartStatisticsRecord) => void;
};

// By default it's all at 5 but we need 20 top to let appear the value above the bars
const CHART_MARGIN = { top: 20, right: 5, bottom: 5, left: 5 };

const getYAxisWidth = (ticks: number[], formatter: (value: number) => string, fontSize: number) =>
  Math.max(...ticks.map((tick) => formatter(tick).length)) * (fontSize / 1.25);

const DEFAULT_STYLES: PayoutAndPerformanceChartStyles = {
  bar: {
    widthSmall: 32,
    widthLarge: 64,
    radiusSmall: [4, 4, 0, 0],
    radiusLarge: [4, 4, 0, 0],
    label: {
      fontSize: 12,
      fontWeight: 500,
    },
  },
  xAxis: {
    offset: 10,
    fontSize: 12,
    fontWeight: 500,
  },
  yAxis: {
    fontSize: 12,
    fontWeight: 400,
  },
};

/**
 * Payout and performance chart: displays payout as bar chart and kpis as line chart.
 * You can choose between 2 payout modes: last 12 months or year to date.
 */
export const PayoutAndPerformanceChart = memo(function PayoutAndPerformanceChart({
  statistics,
  currency,
  hiddenSeries,
  frequency,
  styles,
  isRecordActiveFn,
  onClickBar,
}: PayoutAndPerformanceChartProps) {
  const theme = useTheme();
  const { formatNumber } = useIntl();

  const shouldUseSmallBars = (statistics?.records.length ?? 0) > 6;

  const mergedStyles = useMemo(
    () => merge({}, DEFAULT_STYLES, styles) as RequiredDeep<PayoutAndPerformanceChartStyles>,
    [styles],
  );

  const { seriesMetadata, kpi1DataKey, kpi2DataKey } = useChartSeries(statistics);

  const amountDataKey = statistics?.definitions.ruleMetricPayment__value?.identifier;
  const averageDataKey = amountDataKey ? `${amountDataKey}__average` : undefined;
  const monthDataKey = statistics?.definitions.ruleMetricPeriod__month?.identifier;

  const isKpi1Selected = !!kpi1DataKey && kpi1DataKey in seriesMetadata && !hiddenSeries?.includes(kpi1DataKey);
  const isKpi2Selected = !!kpi2DataKey && kpi2DataKey in seriesMetadata && !hiddenSeries?.includes(kpi2DataKey);
  const isAmountSelected = !!amountDataKey && amountDataKey in seriesMetadata && !hiddenSeries?.includes(amountDataKey);
  const isAverageSelected =
    !!averageDataKey && averageDataKey in seriesMetadata[averageDataKey] && !hiddenSeries?.includes(averageDataKey);

  const amountTicks = useAmountTicks(
    statistics?.records,
    ['ruleMetricPayment__value'],
    [kpi1DataKey, kpi2DataKey].filter(Boolean),
  );

  const { isRecordActiveByIndex } = useActiveRecords({
    records: statistics?.records,
    isRecordActiveFn,
  });

  const recordsWithNoInactiveKpis = useHideKpisForInactiveRecords({
    records: statistics?.records,
    kpi1DataKey,
    kpi2DataKey,
    isRecordActiveByIndex,
  });

  const recordsWithRoundedValues = useRoundRecordValues(recordsWithNoInactiveKpis);

  const { average, records: recordsWithAverage } = useAverage({
    records: recordsWithRoundedValues,
    isRecordActiveByIndex,
    averageDataKey,
  });

  const doesRecordHaveValueByIndex = useCallback(
    (index: number) => Number.isFinite(statistics?.records[index]?.ruleMetricPayment__value?.value),
    [statistics?.records],
  );

  const amountTickFormatter = useCallback(
    (value: number) =>
      value === EMPTY_UPPER_TICK
        ? ''
        : formatChartValue(value, statistics?.definitions.ruleMetricPayment__value?.format, currency),
    [currency, statistics],
  );

  const percentFormatter = useCallback(
    (value: number) => formatNumber(value, { style: 'percent', maximumFractionDigits: 2 }),
    [formatNumber],
  );

  const handleClickChart: CategoricalChartFunc = useCallback(
    (event) => {
      assert(event.activePayload);
      const record = (event.activePayload[0] as { payload: PayoutAndPerformanceChartStatisticsRecord } | undefined)
        ?.payload;
      if (record) {
        onClickBar?.(record);
      }
    },
    [onClickBar],
  );

  const hasPercentAxis = !!(isKpi1Selected || isKpi2Selected) && !!amountTicks.right;
  const hasAmountAxis = !!(isAmountSelected || isAverageSelected) && !!amountTicks.left;

  return (
    <ResponsiveContainer
      height="100%"
      width="100%"
    >
      <ComposedChart
        data={recordsWithAverage}
        margin={CHART_MARGIN}
        onClick={handleClickChart}
      >
        <CartesianGrid
          stroke={theme.ds.colors.gray[100]}
          vertical={false}
        />

        {!!hasAmountAxis && (
          <YAxis
            axisLine={false}
            domain={amountTicks.left!.domain}
            orientation="left"
            tickFormatter={amountTickFormatter}
            tickLine={false}
            ticks={amountTicks.left!.ticks}
            width={getYAxisWidth(amountTicks.left!.ticks, amountTickFormatter, mergedStyles.yAxis.fontSize)}
            yAxisId="amount"
            tick={
              <YAxisTick
                fontSize={mergedStyles.yAxis.fontSize}
                fontWeight={mergedStyles.yAxis.fontWeight}
              />
            }
          />
        )}

        {!!hasPercentAxis && !!amountTicks.right && (
          <YAxis
            axisLine={false}
            domain={amountTicks.right.domain}
            orientation={hasAmountAxis ? 'right' : 'left'}
            tickFormatter={percentFormatter}
            tickLine={false}
            ticks={amountTicks.right.ticks}
            width={getYAxisWidth(amountTicks.right.ticks, percentFormatter, mergedStyles.yAxis.fontSize)}
            yAxisId="percent"
            tick={
              <YAxisTick
                fontSize={mergedStyles.yAxis.fontSize}
                fontWeight={mergedStyles.yAxis.fontWeight}
              />
            }
          />
        )}

        <Tooltip
          allowEscapeViewBox={{ x: false, y: true }}
          filterNull={false}
          reverseDirection={{ x: false, y: true }}
          content={
            <CustomTooltip
              currency={currency}
              frequency={frequency}
              seriesMetadata={seriesMetadata}
            />
          }
        />

        {/*
         * Display the average as a ReferenceLine, but we need to also render it as a Line to get it in the Tooltip.
         * The Line component is transparent and has no dots, so it's not visible.
         */}
        {!!hasAmountAxis && !!isAverageSelected && !!average && (
          <ReferenceLine
            stroke={seriesMetadata[averageDataKey].color}
            strokeDasharray="5 2.5"
            strokeLinecap="round"
            strokeWidth={1}
            y={average}
            yAxisId="amount"
          />
        )}

        {!!hasAmountAxis && !!isAverageSelected && (
          <Line
            activeDot={false}
            dataKey={seriesMetadata[averageDataKey].dataKey}
            dot={false}
            stroke="transparent"
            yAxisId="amount"
          />
        )}

        {!!hasAmountAxis && !!isAmountSelected && (
          <Bar
            barSize={shouldUseSmallBars ? mergedStyles.bar.widthSmall : mergedStyles.bar.widthLarge}
            dataKey={seriesMetadata[amountDataKey].dataKey}
            radius={shouldUseSmallBars ? mergedStyles.bar.radiusSmall : mergedStyles.bar.radiusLarge}
            yAxisId="amount"
          >
            <LabelList
              dataKey={seriesMetadata[amountDataKey].dataKey}
              position="top"
              content={
                <BarLabel
                  currency={currency}
                  fontSize={mergedStyles.bar.label.fontSize}
                  fontWeight={mergedStyles.bar.label.fontWeight}
                  format={seriesMetadata[amountDataKey].format}
                  isRecordActiveByIndex={isRecordActiveByIndex}
                />
              }
            />

            {/* Set the bar color based on active state of each record. */}
            {statistics.records.map((_, index) => (
              <Cell
                key={index} // eslint-disable-line react/no-array-index-key -- It really is the key.
                fill={isRecordActiveByIndex(index) ? theme.ds.hues.blue[900] : theme.ds.colors.gray[100]}
              />
            ))}
          </Bar>
        )}

        <XAxis
          dataKey={monthDataKey}
          height={17}
          interval={0}
          tickLine={false}
          tick={
            <MonthAxisTick
              doesRecordHaveValueByIndex={doesRecordHaveValueByIndex}
              fontSize={mergedStyles.xAxis.fontSize}
              fontWeight={mergedStyles.xAxis.fontWeight}
              frequency={frequency}
              offset={mergedStyles.xAxis.offset}
            />
          }
        />

        {!!hasPercentAxis && !!isKpi1Selected && (
          <Line
            connectNulls
            activeDot={false}
            dataKey={seriesMetadata[kpi1DataKey].dataKey}
            dot={{ fill: seriesMetadata[kpi1DataKey].color }}
            stroke={seriesMetadata[kpi1DataKey].color}
            strokeWidth={2}
            type="monotone"
            yAxisId="percent"
          />
        )}

        {!!hasPercentAxis && !!isKpi2Selected && (
          <Line
            connectNulls
            activeDot={false}
            dataKey={seriesMetadata[kpi2DataKey].dataKey}
            dot={{ fill: seriesMetadata[kpi2DataKey].color }}
            stroke={seriesMetadata[kpi2DataKey].color}
            strokeWidth={2}
            type="monotone"
            yAxisId="percent"
          />
        )}
      </ComposedChart>
    </ResponsiveContainer>
  );
});
