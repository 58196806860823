import { css } from '@emotion/react';
import { memo } from 'react';

import { type Variable } from '@amalia/amalia-lang/tokens/types';
import { Stack, Typography } from '@amalia/design-system/components';
import { type KeysOfStringValues } from '@amalia/ext/typescript';
import { type Filter, type PlansMap, type Rule } from '@amalia/payout-definition/plans/types';

interface DesignerItemWithContextProps {
  readonly object?: Filter | Rule | Variable;
  readonly nameAccessor?: string;
  readonly planIdAccessor?: string;
  readonly nameOverride?: string;
  readonly planNameOverride?: string;
  readonly plansMap: PlansMap;
}

export const DesignerItemWithContext = memo(function DesignerItemWithContext({
  object,
  nameAccessor,
  planIdAccessor,
  nameOverride,
  planNameOverride,
  plansMap,
}: DesignerItemWithContextProps) {
  const planId = object?.[(planIdAccessor as KeysOfStringValues<typeof object> | undefined) || 'planId'];
  const planName = planNameOverride || (planId && planId in plansMap ? plansMap[planId].name : null);

  return (
    <Stack>
      <div>
        <Typography variant={Typography.Variant.BODY_BASE_REGULAR}>
          {nameOverride || object?.[(nameAccessor as keyof typeof object | undefined) || 'name']?.toString()}
        </Typography>
      </div>
      {!!planName && (
        <Typography
          variant={Typography.Variant.BODY_SMALL_REGULAR}
          css={(theme) => css`
            color: ${theme.ds.colors.gray[600]};
          `}
        >
          {planName}
        </Typography>
      )}
    </Stack>
  );
});
