import { useTheme } from '@emotion/react';
import { pickBy } from 'lodash';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';

import { FormatsEnum } from '@amalia/data-capture/fields/types';
import { type PayoutAndPerformanceChartStatistics } from '@amalia/reporting/custom-reports/shared';

import { type ChartSeries, ChartSeriesType } from './chartSeries';
import { findPayoutAndPerformanceChartStatisticsKpiColumns } from './kpiColumns.finder';

export const useChartSeries = (statistics?: PayoutAndPerformanceChartStatistics) => {
  const theme = useTheme();
  const { formatMessage } = useIntl();

  const [kpi1, kpi2] = useMemo(() => {
    const kpiColumns = findPayoutAndPerformanceChartStatisticsKpiColumns(statistics);
    return [kpiColumns.at(0), kpiColumns.at(1)];
  }, [statistics]);

  const amountDefinitionName = statistics?.definitions.ruleMetricPayment__value?.identifier;
  const amountFormat = statistics?.definitions.ruleMetricPayment__value?.format;

  const seriesMetadata: Record<string, ChartSeries> = useMemo(
    () =>
      pickBy(
        {
          [amountDefinitionName ?? '']: amountDefinitionName
            ? {
                label: formatMessage({ defaultMessage: 'Commission' }),
                name: amountDefinitionName,
                color: theme.ds.hues.blue[900],
                type: ChartSeriesType.BAR,
                format: amountFormat ?? FormatsEnum.currency,
                dataKey: `${amountDefinitionName}.value`,
              }
            : undefined,

          [`${amountDefinitionName}__average`]: amountDefinitionName
            ? {
                label: formatMessage({ defaultMessage: 'Commission average' }),
                name: 'average',
                color: `${theme.ds.hues.blue[900]}80`, // 50% opacity in #RRGGBBAA format.
                type: ChartSeriesType.DOTTED_LINE,
                format: amountFormat ?? FormatsEnum.currency,
                dataKey: `${amountDefinitionName}__average`,
              }
            : undefined,

          [kpi1?.identifier ?? '']: kpi1
            ? {
                label: kpi1.label,
                name: kpi1.identifier,
                color: theme.ds.colors.primary[400],
                type: ChartSeriesType.LINE,
                format: FormatsEnum.percent,
                dataKey: kpi1.identifier,
              }
            : undefined,

          [kpi2?.identifier ?? '']: kpi2
            ? {
                label: kpi2.label,
                name: kpi2.identifier,
                color: theme.ds.colors.primary[200],
                type: ChartSeriesType.LINE,
                format: FormatsEnum.percent,
                dataKey: kpi2.identifier,
              }
            : undefined,
        },
        (seriesDefinition) => !!seriesDefinition,
      ),
    [formatMessage, theme, kpi1, kpi2, amountDefinitionName, amountFormat],
  );

  return {
    seriesMetadata,
    kpi1DataKey: kpi1?.identifier,
    kpi2DataKey: kpi2?.identifier,
  };
};
