import { css } from '@emotion/react';
import { type ReactElement, memo, useMemo } from 'react';

import { type AlertBannerProps } from '@amalia/design-system/components';

import {
  StatementSummaryPayoutAndPerformanceChart,
  type StatementSummaryPayoutAndPerformanceChartProps,
} from '../chart/StatementSummaryPayoutAndPerformanceChart';

export type StatementSummaryPayoutAndPerformanceChartContainerProps = StatementSummaryPayoutAndPerformanceChartProps & {
  readonly shouldDimChart?: boolean;
  readonly alert?: ReactElement<AlertBannerProps>;
};

export const StatementSummaryPayoutAndPerformanceChartContainer = memo(
  function StatementSummaryPayoutAndPerformanceChartContainer({
    shouldDimChart: propsShouldDimChart = false,
    alert,
    ...props
  }: StatementSummaryPayoutAndPerformanceChartContainerProps) {
    const emptyStatistics = useMemo(
      () => ({
        definitions: props.statistics?.definitions ?? {},
        records: [],
      }),
      [props.statistics],
    );

    const shouldDimChart = propsShouldDimChart || !!alert;

    return (
      <div
        css={css`
          position: relative;
          height: 124px;
        `}
      >
        <div
          css={[
            css`
              height: 100%;
            `,
            // Lower opacity when the payment category is not achieved because the chart is confusing for users.
            shouldDimChart &&
              css`
                opacity: 0.33;
              `,
            alert &&
              css`
                pointer-events: none; // Prevent tooltip from showing behind the alert.
              `,
          ]}
        >
          <StatementSummaryPayoutAndPerformanceChart
            {...props}
            // Need statistics.definitions to show the chart but we want it to be empty when the chart is not shown.
            statistics={alert ? emptyStatistics : props.statistics}
          />
        </div>

        {!!alert && (
          <div
            css={css`
              position: absolute;
              top: 50%;
              left: 0;
              transform: translate(0, -50%);
              opacity: 1;
            `}
          >
            {alert}
          </div>
        )}
      </div>
    );
  },
);
