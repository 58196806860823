import { css } from '@emotion/react';
import { keyBy } from 'lodash';
import { Fragment, memo, useCallback, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { type Variable } from '@amalia/amalia-lang/tokens/types';
import { Button } from '@amalia/design-system/components';
import { HighlightedKpiIdentifier, type Plan } from '@amalia/payout-definition/plans/types';
import { useVariablesMap } from '@amalia/payout-definition/state';

import { StatementGaugeGroup } from '../../../../statement/statement-gauge-group/StatementGaugeGroup';

import { HighlightedKpiModal, type HighlightedKpiModalProps } from './HighlightedKpiModal';

export type HighlightedKpiSelectorProps = {
  readonly plan: Plan;
  readonly onAdd: HighlightedKpiModalProps['onSubmit'];
  readonly onRemove: (identifier: HighlightedKpiIdentifier) => unknown;
  readonly availableVariables: Variable[];
  readonly isForecast?: boolean;
};

const FAKE_PROGRESS = {
  primary: 60,
  secondary: 70,
};

export const HighlightedKpiSelector = memo(function HighlightedKpiSelector({
  plan,
  onRemove,
  onAdd,
  availableVariables,
  isForecast = false,
}: HighlightedKpiSelectorProps) {
  const [selectedIdentifier, setSelectedIdentifier] = useState<HighlightedKpiIdentifier | null>(null);

  const highlightedKpisMap = useMemo(() => keyBy(plan.highlightedKpis, 'identifier'), [plan]);

  const { data: variablesMap, isLoading } = useVariablesMap();

  const closeModal = useCallback(() => {
    setSelectedIdentifier(null);
  }, [setSelectedIdentifier]);

  const onSubmitProxy: HighlightedKpiModalProps['onSubmit'] = useCallback(
    async (...args) => {
      closeModal();
      await onAdd(...args);
    },
    [onAdd, closeModal],
  );

  const onRemoveProxy: HighlightedKpiModalProps['onRemove'] = useCallback(
    (currentlySelectedIdentifier) => {
      closeModal();
      onRemove(currentlySelectedIdentifier);
    },
    [onRemove, closeModal],
  );

  return (
    <Fragment>
      <div
        css={css`
          display: flex;
          align-items: center;
          gap: 36px;
        `}
      >
        <StatementGaugeGroup
          gap={4}
          isForecast={isForecast}
          progress={FAKE_PROGRESS}
          strokeWidth={4}
          width={80}
        />

        <div
          css={css`
            display: flex;
            flex-direction: column;
            gap: 4px;
          `}
        >
          <Button
            data-testid="primary-kpi"
            isLoading={isLoading}
            size={Button.Size.SMALL}
            onClick={() => setSelectedIdentifier(HighlightedKpiIdentifier.PRIMARY)}
          >
            {HighlightedKpiIdentifier.PRIMARY in highlightedKpisMap ? (
              variablesMap?.[highlightedKpisMap[HighlightedKpiIdentifier.PRIMARY].variableId]?.name
            ) : (
              <FormattedMessage defaultMessage="Set primary highlighted KPI" />
            )}
          </Button>

          <Button
            data-testid="secondary-kpi"
            isLoading={isLoading}
            size={Button.Size.SMALL}
            variant={Button.Variant.PRIMARY}
            onClick={() => setSelectedIdentifier(HighlightedKpiIdentifier.SECONDARY)}
          >
            {HighlightedKpiIdentifier.SECONDARY in highlightedKpisMap ? (
              variablesMap?.[highlightedKpisMap[HighlightedKpiIdentifier.SECONDARY].variableId]?.name
            ) : (
              <FormattedMessage defaultMessage="Set secondary highlighted KPI" />
            )}
          </Button>
        </div>
      </div>

      <HighlightedKpiModal
        availableVariables={availableVariables}
        currentValue={selectedIdentifier ? highlightedKpisMap[selectedIdentifier] : undefined}
        selectedIdentifier={selectedIdentifier}
        onCancel={closeModal}
        onRemove={onRemoveProxy}
        onSubmit={onSubmitProxy}
      />
    </Fragment>
  );
});
