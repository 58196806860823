import { useQuery } from '@tanstack/react-query';
import { keyBy } from 'lodash';

import { assert } from '@amalia/ext/typescript';

import { StatementsApiClient } from '../api-client/statements.api-client';

enum STATEMENT_QUERY_KEYS {
  STATEMENT = 'STATEMENT',
}

export const useStatementByPlanPeriodUser = (planId?: string, userId?: string, periodId?: string) =>
  useQuery({
    enabled: !!planId && !!userId && !!periodId,
    queryKey: [STATEMENT_QUERY_KEYS.STATEMENT, planId, userId, periodId],
    queryFn: async () => {
      assert(planId, 'Plan ID must be defined');
      assert(userId, 'User ID must be defined');
      assert(periodId, 'Period ID must be defined');
      return StatementsApiClient.findStatementByCriteria(periodId, planId, userId);
    },
  });

export const useStatementsByIds = (statementIds: string[]) =>
  useQuery({
    enabled: !!statementIds.length,
    queryKey: [STATEMENT_QUERY_KEYS.STATEMENT, 'by-ids', statementIds],
    queryFn: async () => StatementsApiClient.getStatements(statementIds),
    select: (statements) => keyBy(statements, 'id'),
    initialData: [],
  });
