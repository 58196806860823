import { IconCaretLeft, IconInfoCircle } from '@tabler/icons-react';
import { last } from 'lodash';
import { type Dispatch, memo, type SetStateAction, useCallback, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { type UserStatementsFacets } from '@amalia/core/types';
import { Button, IconButton, Popover, Switch, Typography } from '@amalia/design-system/components';
import { WorkflowProgressBar } from '@amalia/payout-calculation/statements/components';
import {
  type WorkflowStatementState,
  WorkflowStatementStateAction,
} from '@amalia/payout-collaboration/workflows/types';
import { type UserContract, UserRole, type UsersMap } from '@amalia/tenants/users/types';

import { WorkflowStepper } from '../../../workflow/WorkflowStepper/WorkflowStepper';
import { useWorkflowStepName } from '../useWorkflowStepName';

import * as styles from './StatementListWorkflowPresentation.styles';

interface StatementListWorkflowPresentationProps {
  readonly steps: Partial<WorkflowStatementState>[];
  readonly stepNumber: number;
  readonly onNextStep?: (canValidStep: WorkflowStatementStateAction, checked: boolean) => void;
  readonly onPreviousStep?: () => void;
  readonly hasRightToPreviousStepWorkflow: boolean;
  readonly usersMap: UsersMap;
  readonly currentUser: UserContract;
  readonly tooltipOpened?: boolean;
  readonly setTooltipOpened: Dispatch<SetStateAction<boolean>>;
  readonly userStatementsFacets: UserStatementsFacets;
}

export const StatementListWorkflowPresentation = memo(function StatementListWorkflowPresentation({
  steps,
  stepNumber,
  onNextStep,
  onPreviousStep,
  hasRightToPreviousStepWorkflow,
  currentUser,
  usersMap,
  tooltipOpened,
  setTooltipOpened,
  userStatementsFacets,
}: StatementListWorkflowPresentationProps) {
  const { formatMessage } = useIntl();

  const [areAllStatementsCompleted, canValidSteps, workflow] = useMemo(
    () => [
      userStatementsFacets.workflow?.workflowCompleted?.unreviewed.count === 0,
      userStatementsFacets.workflow?.canValidSteps || [],
      userStatementsFacets.workflow?.definition,
    ],
    [userStatementsFacets],
  );

  const haveValidatedStep: boolean = useMemo(
    () =>
      !!workflow &&
      canValidSteps.slice(0, stepNumber).filter((step) => step !== WorkflowStatementStateAction.UNAUTHORIZED).length >
        0,
    [canValidSteps, stepNumber, workflow],
  );

  const workflowAction = useMemo(() => {
    if (!workflow || !canValidSteps[stepNumber]) {
      return WorkflowStatementStateAction.UNAUTHORIZED;
    }

    return canValidSteps[stepNumber];
  }, [canValidSteps, stepNumber, workflow]);

  const canBackStep: boolean = useMemo(() => {
    if (!onPreviousStep || !hasRightToPreviousStepWorkflow || stepNumber === 0) {
      return false;
    }

    return canValidSteps[stepNumber - 1] !== WorkflowStatementStateAction.UNAUTHORIZED;
  }, [canValidSteps, onPreviousStep, hasRightToPreviousStepWorkflow, stepNumber]);

  const isReviewed: boolean = useMemo(
    () =>
      areAllStatementsCompleted || (haveValidatedStep && workflowAction === WorkflowStatementStateAction.UNAUTHORIZED),
    [workflowAction, haveValidatedStep, areAllStatementsCompleted],
  );

  const { formatStepName } = useWorkflowStepName();

  // Compute last validated step name.
  const stepToPrint: {
    // Name of the step to be printed
    name: string;
    // Tells if the step name needs to be preceded by the text "Reviewed By"
    printReviewedBy: boolean;
  } = useMemo(() => {
    // If statement is complete, just print "Reviewed"
    if (areAllStatementsCompleted) {
      return {
        name: '',
        printReviewedBy: false,
      };
    }
    // If user hasn't the right to validate current step AND he already has validated a previous step,
    // Show the previous validated step name
    if (isReviewed && haveValidatedStep) {
      const indexOfLastValidatedStep = last(
        // map into [index, right] to filter the step current user had the right to validate, then get its index
        // to get the step name from the workflow steps
        Object.entries(canValidSteps).filter(([, right]) => right !== WorkflowStatementStateAction.UNAUTHORIZED),
      )?.[0];

      if (indexOfLastValidatedStep) {
        return {
          name: formatStepName(
            steps[parseInt(indexOfLastValidatedStep, 10)]?.stepName || steps[stepNumber]?.stepName || '',
          ),
          printReviewedBy: true,
        };
      }
    }
    // Otherwise, toggle is disabled and current step name is printed
    return {
      name: formatStepName(steps[stepNumber]?.stepName || ''),
      printReviewedBy: true,
    };
  }, [haveValidatedStep, canValidSteps, steps, areAllStatementsCompleted, isReviewed, stepNumber, formatStepName]);

  const canToggleStep: boolean = useMemo(() => {
    // If no workflow definition => all statements are not on the same definition
    if (!workflow || steps.length === 0) {
      return false;
    }

    if (isReviewed && stepNumber > 0) {
      const canValidatePreviousStep = canValidSteps[stepNumber - 1] !== WorkflowStatementStateAction.UNAUTHORIZED;

      // User has the right to back one step if he can validate this step OR if it's the last step AND he's admin

      // User can toggle step :
      // - If he is allowed to validate previous step on the workflow definition.
      // In the case where workflow is complete, only non employees users are allowed to unreview the statements.
      return canValidatePreviousStep && (!areAllStatementsCompleted || currentUser.role !== UserRole.EMPLOYEE);
    }

    return workflowAction !== WorkflowStatementStateAction.UNAUTHORIZED;
  }, [areAllStatementsCompleted, currentUser, isReviewed, workflowAction, workflow, steps, canValidSteps, stepNumber]);

  const onNextStepProxy = useCallback(
    (checked: boolean) => onNextStep?.(workflowAction, checked),
    [workflowAction, onNextStep],
  );

  const shouldShowProgressBar = steps.length > 1;

  const completedWorkflowsCount = userStatementsFacets.workflow?.workflowCompleted?.reviewed.count || 0;
  const unreviewedWorkflowsCount = userStatementsFacets.workflow?.workflowCompleted?.unreviewed.count || 0;
  const totalStatements = completedWorkflowsCount + unreviewedWorkflowsCount;

  return (
    <div css={styles.presentationContainer}>
      {!!onNextStep && (
        <Switch
          checked={isReviewed}
          data-testid="statement-list-workflow-switch"
          disabled={!canToggleStep}
          label={
            <span>
              {!!stepToPrint.printReviewedBy && (
                <Typography variant={Typography.Variant.BODY_BASE_BOLD}>
                  <FormattedMessage
                    defaultMessage="Review by {reviewer}"
                    values={{ reviewer: stepToPrint.name }}
                  />
                </Typography>
              )}

              {!!isReviewed && (
                <Typography variant={Typography.Variant.BODY_BASE_BOLD}>
                  <FormattedMessage defaultMessage="Reviewed" />
                </Typography>
              )}
            </span>
          }
          onChange={onNextStepProxy}
        />
      )}

      {!!workflow && !!shouldShowProgressBar && (
        <div css={styles.workflowDetails}>
          <div css={styles.progressBar}>
            <WorkflowProgressBar
              currentStep={stepNumber}
              totalSteps={workflow.steps.length}
            />
          </div>

          <Popover
            isOpen={tooltipOpened}
            content={
              <Popover.Layout>
                <Popover.Header>
                  <Popover.Title>
                    <FormattedMessage defaultMessage="Workflow summary" />
                  </Popover.Title>
                </Popover.Header>
                <Popover.Body>
                  <div css={styles.totalStatementsReviewed}>
                    <Typography variant={Typography.Variant.BODY_SMALL_MEDIUM}>
                      <FormattedMessage
                        defaultMessage="Total of statements reviewed: {statementsReviewedCount, number}/{statementsCount, number}"
                        values={{
                          statementsReviewedCount: completedWorkflowsCount,
                          statementsCount: totalStatements,
                        }}
                      />
                    </Typography>
                  </div>

                  <WorkflowStepper
                    hideTitle
                    usersMap={usersMap}
                    workflowDefinition={workflow}
                    workflowState={userStatementsFacets.workflow?.steps || []}
                  />

                  <div css={styles.actions}>
                    {!!canBackStep && (
                      <Button
                        icon={<IconCaretLeft />}
                        size={Button.Size.SMALL}
                        variant={Button.Variant.LIGHT}
                        onClick={onPreviousStep}
                      >
                        <FormattedMessage defaultMessage="Undo last step" />
                      </Button>
                    )}
                  </div>
                </Popover.Body>
              </Popover.Layout>
            }
            onChangeIsOpen={setTooltipOpened}
          >
            <IconButton
              icon={<IconInfoCircle />}
              isActive={tooltipOpened}
              label={formatMessage({ defaultMessage: 'View workflow summary' })}
            />
          </Popover>
        </div>
      )}
    </div>
  );
});
