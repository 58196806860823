import { memo, useCallback } from 'react';
import { FormattedList, FormattedMessage } from 'react-intl';

import { AlertBanner, Modal, Stack } from '@amalia/design-system/components';

import { useWorkflowListContext } from '../WorkflowList/useWorkflowListContext';

export const DeleteWorkflowConfirmationModal = memo(function DeleteWorkflowConfirmationModal() {
  const {
    selectedWorkflowPlans,
    isLoadingStatementsUnderReview,
    isWorkflowToDelete,
    areStatementsBeingReview,
    setWorkflowToDelete,
    actions,
  } = useWorkflowListContext();

  const handleDeleteModalClose = useCallback(() => setWorkflowToDelete?.(null), [setWorkflowToDelete]);

  const onClickDeleteCallback = useCallback(() => {
    actions.onClickDelete(isWorkflowToDelete!.rowId);
    setWorkflowToDelete?.(null);
  }, [actions, isWorkflowToDelete, setWorkflowToDelete]);

  return (
    <Modal
      isOpen={!!isWorkflowToDelete?.rowId}
      variant={Modal.Variant.DANGER}
      onClose={handleDeleteModalClose}
    >
      <Modal.Content>
        <Modal.Header>
          <Modal.Title>
            <FormattedMessage defaultMessage="Delete workflow" />
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Stack gap={16}>
            {!!areStatementsBeingReview && (
              <AlertBanner variant={AlertBanner.Variant.WARNING}>
                <FormattedMessage
                  defaultMessage="This workflow is set on plans {plans}.{br}Some of these plans statements are currently being reviewed. Please, either unreview or review them all before."
                  values={{
                    plans: (
                      <FormattedList
                        style="short"
                        type="unit"
                        value={(selectedWorkflowPlans || []).map((p) => p.name)}
                      />
                    ),
                  }}
                />
              </AlertBanner>
            )}

            <div>
              <FormattedMessage
                defaultMessage="Are you sure you want to delete the workflow “<b>{workflowName}</b>”?"
                values={{ workflowName: isWorkflowToDelete?.name, b: (chunks) => <b>{chunks}</b> }}
              />
            </div>
          </Stack>
        </Modal.Body>
      </Modal.Content>

      <Modal.Actions>
        <Modal.CancelAction />

        <Modal.MainAction
          data-testid="delete"
          disabled={areStatementsBeingReview}
          isLoading={isLoadingStatementsUnderReview}
          onClick={onClickDeleteCallback}
        >
          <FormattedMessage defaultMessage="Delete" />
        </Modal.MainAction>
      </Modal.Actions>
    </Modal>
  );
});
