import { css } from '@emotion/react';
import { Link, List, ListSubheader } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { IconSearch } from '@tabler/icons-react';
import { type Editor } from '@tiptap/react';
import { groupBy, mapValues, omit, sortBy } from 'lodash';
import { Fragment, memo, type SyntheticEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { TokenType, type Variable, VariableType } from '@amalia/amalia-lang/tokens/types';
import { type TracingTypes } from '@amalia/core/types';
import { FORMATS_SYMBOLS_AND_LABELS } from '@amalia/data-capture/fields/types';
import { Input } from '@amalia/design-system/components';
import { type AmaliaThemeType } from '@amalia/ext/mui/theme';
import { eventStopPropagation, eventStopPropagationAndPreventDefault } from '@amalia/ext/web';
import { useDebouncedInput } from '@amalia/lib-ui';
import { type Filter, type Relationship } from '@amalia/payout-definition/plans/types';

import { designerObjectsDetails } from '../../../utils/designer';
import {
  type AmaliaFunctionWithId,
  type DesignerAllObjects,
  type DesignerAvailableTypes,
} from '../../designer/drawer/drawer.types';
import { filterAllObjects } from '../../designer/drawer/drawer.utils';

import { FormulaEditorGroupButton } from './FormulaEditorGroupButton';
import { type ClickableOption, FormulaEditorOption } from './FormulaEditorOption';

const useStylesSearchModal = makeStyles((theme: AmaliaThemeType) => ({
  container: {
    display: 'grid',
    flexDirection: 'column',
    alignItems: 'start',
    padding: '10px',
    gridTemplateColumns: '240px',
    gridTemplateRows: 'auto auto',
    gridAutoFlow: 'column',
    gridAutoColumns: '300px',
    columnGap: '10px',
  },
  categoryContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  input: {
    '& svg': {
      color: theme.palette.grey['700'],
    },
  },
  list: {
    maxHeight: '320px',
    overflowX: 'auto',
    '& .MuiListItem-root': {
      padding: 0,
      paddingLeft: '16px',
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: theme.palette.grey['100'],
      },
    },
    '& .MuiListSubheader-root': {
      paddingLeft: '16px',
      backgroundColor: theme.palette.common.white,
    },
    '& ul': {
      paddingLeft: 0,
    },
    '& .MuiListSubheader-sticky': {
      top: '-1px',
    },
  },
  formatSymbol: {
    color: theme.palette.tertiary.main,
    marginLeft: theme.spacing(1),
  },
}));

const getTooltipTitle = (object: DesignerAvailableTypes, designerObjectType: TokenType) => {
  let additionalTitle = '';

  if (designerObjectType === TokenType.PROPERTY) {
    additionalTitle = (object as TracingTypes.Property).definition.machineName;
  }

  if ([TokenType.FILTER, TokenType.FIELD].includes(designerObjectType) && (object as Filter | Variable).object?.name) {
    additionalTitle = (object as Filter | Variable).object!.name;
  }

  if (designerObjectType === TokenType.LINK) {
    return object.name;
  }

  const additionalTitleWithPrefix = additionalTitle ? ` // ${additionalTitle}` : '';
  return `${object.name}${additionalTitleWithPrefix}`;
};

const getFullMachineName = (object: DesignerAvailableTypes, designerObjectType: TokenType) => {
  if ('type' in object && !Object.values(VariableType).includes(object.type)) {
    return (object as Variable).machineName;
  }

  if (designerObjectType === TokenType.LINK) {
    return null;
  }

  return `${'type' in object && object.type === VariableType.object ? object.object?.machineName : (object as Variable).type}.${(object as Variable).machineName}`;
};

const getFormulaToPrint = (object: DesignerAvailableTypes, designerObjectType: TokenType) => {
  const objFormula = 'formula' in object ? object.formula : undefined;
  const objCondition = 'condition' in object ? object.condition : undefined;

  let formula = objFormula || objCondition;

  if (designerObjectType === TokenType.PROPERTY) {
    return `${(object as TracingTypes.Property).definition.machineName}.${object.id}`;
  }

  if (designerObjectType === TokenType.FUNCTION) {
    const fn = object as AmaliaFunctionWithId;
    const params = fn.hasInfiniteParams ? 'param1, param2, ...' : fn.params.map((p) => p.name).join(', ');
    return `${fn.name}(${params})`;
  }

  if (!formula && designerObjectType === TokenType.LINK) {
    const relationship = object as Relationship;
    formula =
      `${relationship.fromDefinitionMachineName}.${relationship.fromDefinitionField}` +
      ` = ${relationship.toDefinitionMachineName}.${relationship.toDefinitionField}`;
  }

  return formula;
};

const listOptionGroups = (designerObjects: DesignerAvailableTypes[], designerObjectType: TokenType) => {
  const objectsGroupedByCategory = groupBy(
    sortBy(designerObjects, 'name'),
    designerObjectsDetails[designerObjectType].group?.field,
  );

  return sortBy(
    Object.entries(objectsGroupedByCategory).map(([key, categoryObjects]) => ({
      label: key,
      options: categoryObjects.map((obj) => ({
        id: obj.id,
        value: designerObjectsDetails[designerObjectType].onCopy?.(obj),
        label: obj.name,
        format: ('format' in obj ? FORMATS_SYMBOLS_AND_LABELS[obj.format] : null) || null,
        tooltipTitle: getTooltipTitle(obj, designerObjectType),
        fullMachineName: getFullMachineName(obj, designerObjectType),
        description: designerObjectsDetails[designerObjectType].getDescription?.(obj) || '',
        formulaToPrint: getFormulaToPrint(obj, designerObjectType),
      })),
    })),
    'label',
  );
};

interface SearchModalProps {
  readonly designerObjects: DesignerAllObjects;
  readonly editor: Editor | null;
  readonly onRefreshScope?: () => void;
}

export const DesignerObjectsModalPicker = memo(function DesignerObjectsModalPicker({
  designerObjects,
  editor,
  onRefreshScope,
}: SearchModalProps) {
  const DesignerObjectsEnum = useMemo(() => {
    const designerAllObjects: TokenType[] = [
      TokenType.FUNCTION,
      TokenType.FILTER,
      TokenType.KEYWORD,
      TokenType.FIELD,
      TokenType.PROPERTY,
      TokenType.VARIABLE,
      TokenType.QUOTA,
      TokenType.LINK,
    ];
    return designerAllObjects;
  }, []);

  // Handle debounced input
  const [searchValue, setSearchValue] = useState<string>('');
  const [searchValueDebounced, setSearchValueDebounced] = useState('');
  const { onChange: onDebouncedSearchInputChange } = useDebouncedInput(setSearchValue, setSearchValueDebounced, 300);
  const { formatMessage } = useIntl();

  const [selectedCategory, setSelectedCategory] = useState<TokenType | null>(null);
  const [clickedCategory, setClickedCategory] = useState<TokenType | null>(null);

  const category = clickedCategory || selectedCategory;

  const classes = useStylesSearchModal();
  const inputRef = useRef<HTMLInputElement | null>(null);

  // For each DesignerObjects (FIELD, VARIABLES ...) we filter the objects data with the value in the input
  // and then we sort the objects to have subgroups (listOptionGroups()).
  const categoriesOptionsGroup = useMemo(() => {
    const filteredDesignerObjects = filterAllObjects(designerObjects, { needle: searchValueDebounced });
    return mapValues(omit(filteredDesignerObjects, [TokenType.PLAN, TokenType.RULE]), (value, key) => {
      const group = designerObjectsDetails[key as TokenType].group;
      return group
        ? listOptionGroups(value, key as TokenType)
        : [{ label: key, options: listOptionGroups(value, key as TokenType).at(0)?.options || [] }];
    });
  }, [designerObjects, searchValueDebounced]);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [inputRef]);

  const [isDrawerOpened, setIsDrawerOpened] = useState<boolean>(false);

  const handleCategoryHover = useCallback(
    (designerObjectsType: TokenType) => () => {
      setSelectedCategory(designerObjectsType);
      setIsDrawerOpened(true);
    },
    [],
  );
  const handleCategoryClick = useCallback(
    (designerObjectsType: TokenType) => () => {
      setClickedCategory((prev) => (prev === designerObjectsType ? null : designerObjectsType));
      setIsDrawerOpened(true);
    },
    [],
  );
  const handleCategoryListLeave = useCallback(() => {
    setSelectedCategory(null);
    setClickedCategory(null);
    setIsDrawerOpened(false);
  }, []);

  const handleListItemClick = useCallback(
    (type: TokenType, option: ClickableOption) => (event: SyntheticEvent) => {
      // IF used with an editor, append the item selected
      if (editor) {
        if (type === 'FUNCTION') {
          const amaliaFunction = designerObjects.FUNCTION.find((f) => f.name === option.value) as AmaliaFunctionWithId;
          editor.commands.appendFunction({ amaliaFunction });
          editor.commands.focus();
        } else {
          editor.commands.insertContent(option.value.toString());
          editor.commands.focus();
        }
      }

      eventStopPropagationAndPreventDefault(event);
    },
    [designerObjects.FUNCTION, editor],
  );

  return (
    <div
      className={classes.container}
      id="formulaEditorCategoryGridContainer"
      onMouseLeave={handleCategoryListLeave}
    >
      <Input
        ref={inputRef}
        action={<Input.ClearAction onClick={() => setSearchValue('')} />}
        data-testid="searchInput"
        leftIcon={<IconSearch />}
        placeholder={formatMessage({ defaultMessage: 'Search' })}
        value={searchValue}
        onChange={onDebouncedSearchInputChange}
        onClick={eventStopPropagation}
      />

      <div className={classes.categoryContainer}>
        {DesignerObjectsEnum.map((designerObjectsType) => (
          <FormulaEditorGroupButton
            key={designerObjectsType}
            designerObjectsType={designerObjectsType}
            handleClick={handleCategoryClick(designerObjectsType)}
            handleHover={handleCategoryHover(designerObjectsType)}
            matchesFound={categoriesOptionsGroup[designerObjectsType as keyof typeof categoriesOptionsGroup].reduce(
              (prev, curr) => prev + curr.options.length,
              0,
            )}
            selected={
              clickedCategory ? clickedCategory === designerObjectsType : selectedCategory === designerObjectsType
            }
          />
        ))}
        {onRefreshScope ? (
          <div
            css={css`
              text-align: center;
              border: 1px solid red;
            `}
          >
            <Link
              underline="hover"
              onClick={onRefreshScope}
            >
              <FormattedMessage defaultMessage="Refresh scope" />
            </Link>
          </div>
        ) : null}
      </div>
      {isDrawerOpened ? (
        <Fragment>
          <div />
          {category ? (
            <List
              disablePadding
              className={classes.list}
            >
              {categoriesOptionsGroup[category as keyof typeof categoriesOptionsGroup].map((group) => (
                <li key={group.label}>
                  <ul>
                    <ListSubheader>{group.options.length > 0 && group.label}</ListSubheader>
                    {group.options.map((option) => (
                      <FormulaEditorOption
                        key={option.id}
                        formatSymbolClass={classes.formatSymbol}
                        handleListItemClick={handleListItemClick(category, option as ClickableOption)}
                        option={option as ClickableOption}
                        tokenType={category}
                      />
                    ))}
                  </ul>
                </li>
              ))}
            </List>
          ) : null}
        </Fragment>
      ) : null}
    </div>
  );
});
