import { useFormikContext } from 'formik';
import moment from 'moment';
import { memo, useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';

import { FormatsEnum } from '@amalia/data-capture/fields/types';
import { DatePicker } from '@amalia/design-system/components';
import { assert } from '@amalia/ext/typescript';

import { OverwriteFormField, type OverwriteFormValuesShape } from '../../overwrite.types';

import { messages } from './selectors.messages';

export const DateTimeOverwriteSelector = memo(function DateTimeOverwriteSelector() {
  const { formatMessage } = useIntl();
  const { values, setFieldValue } = useFormikContext<OverwriteFormValuesShape>();

  const handleChange = useCallback(
    (date: Date | null) => {
      assert(date, 'Date should not be null');
      return setFieldValue(OverwriteFormField.NEW_VALUE, moment(date).format('YYYY-MM-DDTHH:mm:s1.SSS+0000'));
    },
    [setFieldValue],
  );

  const value = useMemo(() => (values.newValue ? new Date(values.newValue) : null), [values.newValue]);

  return (
    <DatePicker
      dateFormat="yyyy-MM-dd"
      id={OverwriteFormField.NEW_VALUE}
      isClearable={false}
      name={OverwriteFormField.NEW_VALUE}
      placeholder={formatMessage(messages[FormatsEnum['date-time']])}
      size={DatePicker.Size.SMALL}
      value={value}
      onChange={handleChange}
    />
  );
});
