import { Popover } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { IconVariable } from '@tabler/icons-react';
import Document from '@tiptap/extension-document';
import History from '@tiptap/extension-history';
import Paragraph from '@tiptap/extension-paragraph';
import Text from '@tiptap/extension-text';
import { useEditor } from '@tiptap/react';
import { memo, useCallback, useState, Fragment, type MouseEventHandler } from 'react';
import { useIntl } from 'react-intl';

import { Group, IconButton } from '@amalia/design-system/components';
import { type AmaliaThemeType } from '@amalia/ext/mui/theme';

import { type DesignerAllObjects } from '../../designer/drawer/drawer.types';

import { FunctionNode, formulaToHtml } from './designerExtension';
import { DesignerObjectsModalPicker } from './DesignerObjectsModalPicker';
import { FormulaEditorContent } from './FormulaEditorContent';

const useStyles = makeStyles((theme: AmaliaThemeType) => ({
  editor: {
    marginTop: '10px',
    border: '3px solid',
    borderRadius: 5,
    borderColor: 'red',
    backgroundColor: theme.palette.common.white,
    minHeight: '6rem',
    display: 'inline-flex',
    flexWrap: 'nowrap',
    width: '100%',
  },
  formula: {
    alignSelf: 'flex-start',
    width: '100%',
  },
}));

export interface FormulaEditorProps {
  readonly name: string;
  readonly id: string;
  readonly designerObjects?: DesignerAllObjects;
  readonly formula: string;
  readonly onFormulaChange: (value: string) => void;
  readonly onBlur?: (e: FocusEvent) => void;
  readonly onRefreshScope?: () => void;
}

/**
 * Formula editor.
 */
export const FormulaEditor = memo(function FormulaEditor({
  designerObjects,
  formula,
  onFormulaChange,
  onBlur,
  name,
  id,
  onRefreshScope,
}: FormulaEditorProps) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const { formatMessage } = useIntl();

  const handleVariableIconClick: MouseEventHandler<HTMLButtonElement> = useCallback(
    (event) => {
      event.stopPropagation();
      event.preventDefault();
      setAnchorEl(event.currentTarget);
    },
    [setAnchorEl],
  );

  const handlePopoverClose = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);

  const editor = useEditor(
    {
      extensions: [Document, FunctionNode, History, Paragraph, Text],
      editorProps: { attributes: { name, id, 'data-testid': id } },
      content: formulaToHtml(formula, designerObjects),
      onUpdate: ({ transaction }) => {
        onFormulaChange(transaction.doc.textContent);
      },
      onBlur: onBlur
        ? ({ event }) => {
            onBlur(event);
          }
        : () => {},
    },
    [designerObjects],
  );

  return (
    <Group
      align="center"
      justify="flex-start"
      onClick={() => editor?.commands.focus()}
    >
      {!!designerObjects && !!editor && (
        <Fragment>
          <IconButton
            data-testid="designerObjectsPicker"
            icon={<IconVariable />}
            label={formatMessage({ defaultMessage: 'Search' })}
            onClick={handleVariableIconClick}
          />
          <Popover
            anchorEl={anchorEl}
            data-testid="categoryPopper"
            id="categoryPopper"
            open={!!anchorEl}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            onClose={handlePopoverClose}
          >
            <DesignerObjectsModalPicker
              designerObjects={designerObjects}
              editor={editor}
              onRefreshScope={onRefreshScope}
            />
          </Popover>
        </Fragment>
      )}

      <div className={classes.formula}>
        <FormulaEditorContent editor={editor} />
      </div>
    </Group>
  );
});
