import { css } from '@emotion/react';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { capitalize } from 'lodash';
import moment from 'moment';
import { memo, type ReactNode, useContext } from 'react';
import { FormattedMessage } from 'react-intl';

import {
  formatTotal,
  formatUserFullName,
  formatValueOrPrintRemovedLabel,
  formatValueTotal,
  type Overwrite,
  TracingTypes,
  type DatasetRow,
} from '@amalia/core/types';
import { type RecordContent, type RecordContentPropertyType } from '@amalia/data-capture/connectors/types';
import { FormatsEnum } from '@amalia/data-capture/fields/types';
import { OverwriteTooltip } from '@amalia/data-correction/overwrites/components';
import { Group, Paper, Typography } from '@amalia/design-system/components';
import { type CurrencySymbolsEnum } from '@amalia/ext/iso-4217';
import { AMALIA_OBJECTS_HIGHLIGHT_COLOR, amaliaTheme, type AmaliaThemeType, colors } from '@amalia/ext/mui/theme';
import { isCurrencyValue } from '@amalia/kernel/monetary/types';
import { TracingContext } from '@amalia/lib-ui';
import { type ComputedOverwrite } from '@amalia/payout-calculation/types';

import { TracingFormulaOptionsTooltip } from './TracingFormulaOptionsTooltip';
import { useTracingStyles } from './useTracingStyles';

const useStyles = makeStyles((theme: AmaliaThemeType) => ({
  root: {
    position: 'relative',
    minHeight: '8rem',
    display: 'flex',
    alignItems: 'stretch',
  },
  titleRotate: {
    transformOrigin: 'center center',
    transform: 'rotate(-90deg)',
  },
  formula: {
    flex: 1,
    display: 'flex',
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: 'calc(100% - 3rem)',
  },
  titleContainer: {
    padding: `0 ${theme.spacing(0.25)} 0 ${theme.spacing(0.25)}`,
    border: '1px solid transparent',
    borderRadius: theme.shape.borderRadius,
    flex: 'none',
  },
  title: {
    whiteSpace: 'nowrap',
  },

  // Styles according to the tracing block type
  [`titleContainer_${TracingTypes.TracingBlockType.FILTER}`]: {
    backgroundColor: AMALIA_OBJECTS_HIGHLIGHT_COLOR.filter,
    color: 'rgba(0, 0, 0, .4)',
  },
  [`titleContainer_${TracingTypes.TracingBlockType.FUNCTION}`]: {
    borderColor: theme.palette.secondary.main,
    color: theme.palette.secondary.main,
  },
  [`titleContainer_${TracingTypes.TracingBlockType.CUSTOM_OBJECT}`]: {
    backgroundColor: AMALIA_OBJECTS_HIGHLIGHT_COLOR.object,
    color: 'rgba(0, 0, 0, .4)',
  },
  [`titleContainer_${TracingTypes.TracingBlockType.RULE}`]: {
    backgroundColor: AMALIA_OBJECTS_HIGHLIGHT_COLOR.rule,
    color: 'rgba(0, 0, 0, .4)',
  },
  [`titleContainer_${TracingTypes.TracingBlockType.STATEMENT}`]: {
    backgroundColor: AMALIA_OBJECTS_HIGHLIGHT_COLOR.statement,
    color: 'rgba(0, 0, 0, .4)',
  },
  [`titleContainer_${TracingTypes.TracingBlockType.TABLE}`]: {
    borderColor: colors['blue-500'],
    color: colors['blue-500'],
  },
  [`titleContainer_${TracingTypes.TracingBlockType.USER}`]: {
    backgroundColor: AMALIA_OBJECTS_HIGHLIGHT_COLOR.user,
    color: 'rgba(0, 0, 0, .4)',
  },
  [`titleContainer_${TracingTypes.TracingBlockType.TEAM}`]: {
    backgroundColor: AMALIA_OBJECTS_HIGHLIGHT_COLOR.team,
    color: 'rgba(0, 0, 0, .4)',
  },
  [`titleContainer_${TracingTypes.TracingBlockType.PLAN}`]: {
    backgroundColor: AMALIA_OBJECTS_HIGHLIGHT_COLOR.plan,
    color: 'rgba(0, 0, 0, .4)',
  },
  [`titleContainer_${TracingTypes.TracingBlockType.VARIABLE}`]: {
    color: amaliaTheme.palette.link.main,
  },
  [`titleContainer_${TracingTypes.TracingBlockType.ROW_MARGINAL}`]: {
    backgroundColor: colors['orange-100'],
    color: 'rgba(0, 0, 0, .4)',
  },
}));

interface TracingBlockProps {
  readonly type: TracingTypes.TracingBlockType;
  readonly children: ReactNode;
  readonly value?: string;
  readonly overwrite?: Overwrite;
  readonly machineName?: string;
  readonly options?: { customObjectMachineName: string };
  readonly formula?: string;
  readonly statementCurrency: CurrencySymbolsEnum;
  readonly format?: FormatsEnum;
  readonly row?: DatasetRow;
}

interface TracingBlockValueProps {
  readonly value?: string;
  readonly overwrite?: ComputedOverwrite | Overwrite;
  readonly machineName?: string;
  readonly statementCurrency: CurrencySymbolsEnum;
  readonly format?: FormatsEnum;
  readonly row?: DatasetRow;
}

const TracingBlockValue = function TracingBlockValue({
  value,
  overwrite,
  machineName,
  statementCurrency,
  format,
  row,
}: TracingBlockValueProps) {
  const { statementResults } = useContext(TracingContext);
  if (!!overwrite && !!machineName) {
    // Check if row overwrite or computed overwrite
    if ((overwrite as Overwrite).appliesToExternalId) {
      // TODO: should use a discriminant type here.
      const sourceValue = (overwrite.sourceValue as RecordContent)[machineName];
      const overwriteValue = (overwrite.overwriteValue as RecordContent)[machineName];
      return (
        <OverwriteTooltip
          isReadOnly
          author={formatUserFullName((overwrite as Overwrite).creator)}
          date={moment(overwrite.createdAt, 'YYYY-MM-DD').format('MMMM Do, YYYY')}
          valueFormat={format}
          newValue={
            isCurrencyValue(overwriteValue)
              ? formatTotal(overwriteValue.value, FormatsEnum.currency, overwriteValue.symbol, 1)
              : formatValueTotal(overwriteValue)
          }
          oldValue={
            isCurrencyValue(sourceValue)
              ? formatTotal(sourceValue.value, FormatsEnum.currency, sourceValue.symbol, 1)
              : formatValueTotal(sourceValue)
          }
        >
          <Typography variant={Typography.Variant.BODY_BASE_REGULAR}>
            {`= ${formatValueOrPrintRemovedLabel(value)}`}
          </Typography>
        </OverwriteTooltip>
      );
    }

    // TODO: should use a discriminant type here.
    const sourceValue = overwrite.sourceValue as RecordContentPropertyType;
    const overwriteValue = overwrite.overwriteValue as RecordContentPropertyType;

    return (
      <OverwriteTooltip
        isReadOnly
        author={(overwrite as ComputedOverwrite).creator}
        date={moment(overwrite.createdAt, 'YYYY-MM-DD').format('MMMM Do, YYYY')}
        valueFormat={format}
        newValue={
          isCurrencyValue(overwriteValue)
            ? formatTotal(overwriteValue.value, FormatsEnum.currency, overwriteValue.symbol, 1)
            : formatValueTotal(overwriteValue)
        }
        oldValue={
          isCurrencyValue(sourceValue)
            ? formatTotal(sourceValue.value, FormatsEnum.currency, sourceValue.symbol, 1)
            : formatValueTotal(sourceValue)
        }
      >
        <Typography variant={Typography.Variant.BODY_BASE_REGULAR}>
          {`= ${formatValueOrPrintRemovedLabel(value)}`}
        </Typography>
      </OverwriteTooltip>
    );
  }

  return (
    <div
      css={css`
        display: flex;
        gap: 8px;
      `}
    >
      <Typography variant={Typography.Variant.BODY_BASE_REGULAR}>
        {`= ${formatValueOrPrintRemovedLabel(value)}`}
      </Typography>
      <TracingFormulaOptionsTooltip
        customObjects={statementResults.definitions.customObjects}
        row={row}
        statementCurrency={statementCurrency}
        formulaOptions={
          machineName && machineName in statementResults.definitions.variables
            ? statementResults.definitions.variables[machineName].formulaOptions
            : undefined
        }
      />
    </div>
  );
};

/**
 * TracingBlock
 * Details a specific tracing formula, along with its type
 */
export const TracingBlock = memo(function TracingBlock({
  type,
  children,
  value,
  formula,
  overwrite,
  machineName,
  options,
  statementCurrency,
  format,
  row,
}: TracingBlockProps) {
  const classes = useStyles();
  const tracingStyles = useTracingStyles();

  const tracingBlockTypeValue =
    type === TracingTypes.TracingBlockType.CUSTOM_OBJECT && options?.customObjectMachineName
      ? capitalize(options.customObjectMachineName)
      : capitalize(type);

  return (
    <Paper
      className={classes.root}
      data-testid={`Trace formula: ${formula}`}
    >
      <Group
        align="center"
        className={clsx(classes.titleContainer, classes[`titleContainer_${type}`])}
        css={css`
          margin: 16px;
        `}
      >
        <Group
          className={classes.titleRotate}
          justify="center"
          css={css`
            height: 1rem;
            max-width: 1rem;
            width: 1rem;
          `}
        >
          <div className={classes.title}>{tracingBlockTypeValue}</div>
        </Group>
      </Group>

      <div className={classes.formula}>
        <div className={tracingStyles.result}>
          {overwrite ? <FormattedMessage defaultMessage="This value was overwritten" /> : children}
        </div>

        {!!value && (
          <div className={tracingStyles.total}>
            <TracingBlockValue
              format={format}
              machineName={machineName}
              overwrite={overwrite}
              row={row}
              statementCurrency={statementCurrency}
              value={value}
            />
          </div>
        )}
      </div>
    </Paper>
  );
});
