import { css, type Theme } from '@emotion/react';
import { memo } from 'react';

import { reformatDateString } from '@amalia/core/types';
import { Typography } from '@amalia/design-system/components';
import { TypographyVariant } from '@amalia/design-system/meta';
import { type CurrencySymbolsEnum } from '@amalia/ext/iso-4217';
import { PeriodFrequencyEnum } from '@amalia/payout-definition/periods/types';

import { getDefinitionNameFromDataKey, type ChartSeries } from '../../chartSeries';

import { TooltipItem } from './TooltipItem';

type TooltipProps = {
  readonly seriesMetadata?: Record<string, ChartSeries>;
  readonly currency: CurrencySymbolsEnum;
  readonly active?: boolean;
  readonly payload?: { name: string; value: number; unit: string }[];
  readonly label?: string;
  readonly frequency?: PeriodFrequencyEnum;
};

const tooltipContainerCss = (theme: Theme) => css`
  display: flex;
  flex-direction: column;
  gap: 8px;
  background-color: ${theme.ds.colors.gray[0]};
  padding: 16px 20px;
  border-radius: ${theme.ds.borderRadiuses.squared};
  box-shadow: ${theme.ds.shadows.soft};
  border: 1px solid ${theme.ds.colors.gray[100]};
`;

export const Tooltip = memo(function Tooltip({
  active,
  payload,
  label,
  seriesMetadata,
  currency,
  frequency,
}: TooltipProps) {
  if (active && !!payload?.length) {
    const monthLabel = label
      ? reformatDateString(label, 'MM/YYYY', frequency === PeriodFrequencyEnum.quarter ? '[Q]Q YYYY' : 'MMMM YYYY')
      : '';

    const seriesMetadataOrder = Object.keys(seriesMetadata ?? {});

    return (
      <div css={tooltipContainerCss}>
        <Typography variant={TypographyVariant.BODY_SMALL_BOLD}>{monthLabel}</Typography>
        {payload
          .toSorted((a, b) => seriesMetadataOrder.indexOf(a.name) - seriesMetadataOrder.indexOf(b.name))
          .map((item) => (
            <TooltipItem
              key={item.name}
              currency={currency}
              series={seriesMetadata?.[getDefinitionNameFromDataKey(item.name)]}
              value={item.value}
            />
          ))}
      </div>
    );
  }

  return null;
});
