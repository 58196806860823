import { defineMessages } from 'react-intl';

import { FormatsEnum } from '@amalia/data-capture/fields/types';

export const formatsEnumMessages = defineMessages<FormatsEnum>({
  [FormatsEnum.number]: {
    defaultMessage: 'number',
  },
  [FormatsEnum.boolean]: {
    defaultMessage: 'boolean',
  },
  [FormatsEnum.date]: {
    defaultMessage: 'date',
  },
  [FormatsEnum['date-time']]: {
    defaultMessage: 'date-time',
  },
  [FormatsEnum.percent]: {
    defaultMessage: 'percent',
  },
  [FormatsEnum.table]: {
    defaultMessage: 'table',
  },
  [FormatsEnum.currency]: {
    defaultMessage: 'currency',
  },
  [FormatsEnum.text]: {
    defaultMessage: 'text',
  },
});
