import { isNil } from 'lodash';
import { useMemo } from 'react';

import { isCurrencyValue } from '@amalia/kernel/monetary/types';
import { type PayoutAndPerformanceChartStatisticsRecord } from '@amalia/reporting/custom-reports/shared';

import { getDomainAndTicks } from './amountTickLevels.mapper';

const getMinMaxInCollection = (elements: PayoutAndPerformanceChartStatisticsRecord[], keys: string[]) => {
  const collection = elements
    .flatMap((elt) =>
      keys.map((key) => {
        const value = elt[key];
        return isCurrencyValue(value) ? value.value : (value as number);
      }),
    )
    // Careful, 0 values are eligible.
    .filter((v) => !isNil(v));

  return {
    min: 0,
    max: collection.length ? Math.max(...collection) : 0,
  };
};

/**
 * Custom hook to compute amount ticks.
 * @param statistics
 * @param leftKeys
 * @param rightKeys
 */
export const useAmountTicks = (
  records: PayoutAndPerformanceChartStatisticsRecord[] | null | undefined,
  leftKeys: string[],
  rightKeys: string[],
) =>
  useMemo(() => {
    if (!records) {
      return { left: null, right: null };
    }

    const leftMinMax = getMinMaxInCollection(records, leftKeys);
    const rightMinMax = getMinMaxInCollection(records, rightKeys);

    return getDomainAndTicks(leftMinMax.min, leftMinMax.max, rightMinMax.min, rightMinMax.max);
  }, [records, leftKeys, rightKeys]);
