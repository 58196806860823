import { createContext, useContext } from 'react';

import { assert } from '@amalia/ext/typescript';
import { type WorkflowDefinition } from '@amalia/payout-collaboration/workflows/types';
import { type Plan } from '@amalia/payout-definition/plans/types';

export interface WorkflowListContextInterface {
  actions: {
    onClickEdit: (workflow: WorkflowDefinition) => void;
    onClickDelete: (workflowId: string) => void;
    onClickDuplicate: (workflow: WorkflowDefinition) => void;
  };
  isWorkflowToDelete?: { rowId: string; name: string } | null;
  setWorkflowToDelete?: (value: { rowId: string; name: string } | null) => void;
  areStatementsBeingReview?: boolean;
  selectedWorkflowPlans?: Plan[];
  isLoadingStatementsUnderReview?: boolean;
}

const WorkflowListContext = createContext<WorkflowListContextInterface | null>(null);

export const WorkflowListProvider = WorkflowListContext.Provider;

export const useWorkflowListContext = () => {
  const context = useContext(WorkflowListContext);
  assert(context, 'useWorkflowListContext must be used within a WorkflowListContext');
  return context;
};
