import { uniq } from 'lodash';
import { useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import useAsyncEffect from 'use-async-effect';

import { type MainKpi, type Statement } from '@amalia/core/types';
import { useSnackbars } from '@amalia/design-system/components';
import { toError } from '@amalia/ext/typescript';
import * as ChallengeRepository from '@amalia/frontend/web-data-layers';
import {
  fetchPaymentsForStatement,
  fetchUsers,
  selectPaymentsByCategoryForCurrentStatement,
  useThunkDispatch,
} from '@amalia/frontend/web-data-layers';
import { type Rule, type Challenge, type PlanRule } from '@amalia/payout-definition/plans/types';

type UseChallengeResultProps = {
  originalStatement: Statement | null;
  rule: PlanRule | Rule | null;
};

export const useChallengeResult = ({ originalStatement, rule }: UseChallengeResultProps) => {
  const dispatch = useThunkDispatch();
  const { snackError } = useSnackbars();
  const { formatMessage } = useIntl();

  const payments = useSelector(selectPaymentsByCategoryForCurrentStatement);

  const [challenge, setChallenge] = useState<Challenge | null>(null);

  useAsyncEffect(async () => {
    if (!originalStatement?.period || !rule?.id) {
      return;
    }
    try {
      const challengeFromApi = await ChallengeRepository.getChallenge(rule.id, originalStatement.period.id);

      if (!challengeFromApi) {
        setChallenge(null);
        return;
      }
      setChallenge(challengeFromApi);

      const userIdsToFetch = uniq((challengeFromApi.leaderboard || []).map((r) => r.userId).filter(Boolean));
      if (userIdsToFetch.length) {
        await dispatch(fetchUsers(userIdsToFetch));
        await dispatch(fetchPaymentsForStatement(originalStatement.id));
      }
    } catch (e) {
      snackError(toError(e).message);
    }
  }, [originalStatement]);

  // Format the display we put on the rule summary.
  const mainKpi = useMemo((): MainKpi | MainKpi[] => {
    if (!challenge?.results) {
      return {
        label: formatMessage({ defaultMessage: 'Rank' }),
        value: 0,
        overrideFormattedValue: '-',
      };
    }

    // Number if you have a rank, null if non eligible, undefined if not loaded.
    const currentUserPosition = originalStatement?.user
      ? challenge.leaderboard?.find((l) => l.userId === originalStatement.user?.id)?.position
      : null;
    const contestantsLength = challenge.leaderboard?.length;

    // If null, it means you're not eligible.
    const rank =
      currentUserPosition === null
        ? formatMessage({ defaultMessage: 'Ineligible' })
        : currentUserPosition
          ? // If undefined, it means it's not loaded.
            formatMessage(
              { defaultMessage: '{userPosition, number} / {contestantsCount, number}' },
              {
                userPosition: currentUserPosition,
                contestantsCount: contestantsLength,
              },
            )
          : formatMessage(
              { defaultMessage: '- / {contestantsCount, number}' },
              { contestantsCount: contestantsLength },
            );

    // If the challenge is non-payout, display something like:
    // Rank
    // 2 / 5
    // If the challenge is payout, display something like:
    // Payout  | Rank
    // 1500 €  | 2 / 5

    // First try to recover the associated payout.
    const payment = rule?.configuration?.challengePricesTableVariableId
      ? payments.paid.find((p) => p.challengeId === challenge.id)
      : null;

    return [
      !!payment && {
        label: formatMessage({ defaultMessage: 'Payout' }),
        value: {
          amount: payment.value || 0,
          currencySymbol: payment.currency,
          // Putting 1 here because the generated payment for the challenge
          // has already the right currency at the right rate.
          currencyRate: 1,
        },
      },
      {
        label: formatMessage({ defaultMessage: 'Rank' }),
        value: 0,
        overrideFormattedValue: rank,
      },
    ].filter(Boolean);
  }, [
    challenge?.results,
    challenge?.leaderboard,
    challenge?.id,
    formatMessage,
    rule?.configuration?.challengePricesTableVariableId,
    payments.paid,
    originalStatement,
  ]);

  return {
    challenge,
    mainKpi,
  };
};
