import { formatValue } from '@amalia/core/types';
import { FormatsEnum } from '@amalia/data-capture/fields/types';
import { type CurrencySymbolsEnum } from '@amalia/ext/iso-4217';
import { assert } from '@amalia/ext/typescript';

/**
 * Format currency amount to short format (1.2k, 1.2m, 1.2b, 1.2t)
 * @param amount
 * @param currency
 */
const formatCurrencyAmountShortFormat = (amount: number, currency: CurrencySymbolsEnum) =>
  new Intl.NumberFormat(undefined, {
    notation: 'compact',
    compactDisplay: 'short',
    style: 'currency',
    currency,
  }).format(amount);

/**
 * Format value based on the format type.
 * @param value
 * @param format
 * @param currency
 */
export const formatChartValue = (value: number, format?: FormatsEnum, currency?: CurrencySymbolsEnum): string => {
  switch (format) {
    case FormatsEnum.currency: {
      assert(currency, 'currency is required');
      return formatCurrencyAmountShortFormat(value, currency);
    }
    case FormatsEnum.percent:
      return formatValue(value, FormatsEnum.percent);
    default:
      return `${value}`;
  }
};
