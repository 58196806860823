import { css } from '@emotion/react';
import { isNil } from 'lodash';
import { type ForwardedRef, memo, useMemo, forwardRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { type CurrencyAmount, formatTotal, type MainKpi, type PaymentCategory } from '@amalia/core/types';
import { FormatsEnum } from '@amalia/data-capture/fields/types';
import { Typography } from '@amalia/design-system/components';
import { assert } from '@amalia/ext/typescript';
import { ForecastAmount } from '@amalia/payout-calculation/forecasts/components';
import { paymentCategoryMessages } from '@amalia/payout-calculation/payments/intl';
import { ruleTypeTranslatedMessages } from '@amalia/payout-definition/plans/intl';
import { type RuleType } from '@amalia/payout-definition/plans/types';

interface KpiRuleItemProps {
  readonly mainKpi: MainKpi;
  readonly variant?: 'default' | 'small';
}

const KpiRuleItemForwardRef = forwardRef(function KpiRuleItem(
  { mainKpi, variant = 'default' }: KpiRuleItemProps,
  ref: ForwardedRef<HTMLDivElement>,
) {
  const { formatMessage } = useIntl();

  const isNilValue =
    !mainKpi.overrideFormattedValue && (isNil(mainKpi.value) || isNil((mainKpi.value as CurrencyAmount).amount));

  const formattedTotal = useMemo(() => {
    if (mainKpi.overrideFormattedValue) {
      return mainKpi.overrideFormattedValue;
    }

    if (isNilValue) {
      return <FormattedMessage defaultMessage="No value" />;
    }

    const valueAsAmount = mainKpi.value as CurrencyAmount;

    assert(!isNil(valueAsAmount.amount), 'mainKpi.value should be a CurrencyAmount');

    return formatTotal(
      valueAsAmount.amount,
      FormatsEnum.currency,
      valueAsAmount.currencySymbol,
      valueAsAmount.currencyRate,
    );
  }, [mainKpi, isNilValue]);

  const mainKpiLabel = useMemo(() => {
    if (mainKpi.label) {
      return mainKpi.label;
    }

    if (mainKpi.type && mainKpi.type in ruleTypeTranslatedMessages) {
      return formatMessage(ruleTypeTranslatedMessages[mainKpi.type as RuleType]);
    }

    if (mainKpi.type && mainKpi.type in paymentCategoryMessages) {
      return formatMessage(paymentCategoryMessages[mainKpi.type as PaymentCategory]);
    }

    return '';
  }, [formatMessage, mainKpi.label, mainKpi.type]);

  return (
    <div
      ref={ref}
      css={css`
        text-align: right;
      `}
    >
      {!!(mainKpi.label || mainKpi.type) && (
        <Typography
          variant={Typography.Variant.BODY_XSMALL_REGULAR}
          css={(theme) => css`
            color: ${theme.ds.colors.gray[600]};
          `}
        >
          {mainKpi.isForecastedView
            ? formatMessage({ defaultMessage: 'Forecasted {rulelabel}' }, { rulelabel: mainKpiLabel })
            : mainKpiLabel}
        </Typography>
      )}

      <div
        aria-label={formatMessage({ defaultMessage: 'Rule total' })}
        css={(theme) => css`
          color: ${isNilValue ? theme.ds.colors.gray[500] : theme.ds.colors.gray[900]};
        `}
      >
        {mainKpi.isForecastedView && mainKpi.isValueForecasted && !isNilValue ? (
          <ForecastAmount
            inline
            variant={variant === 'small' ? Typography.Variant.BODY_LARGE_REGULAR : Typography.Variant.HEADING_4_MEDIUM}
          >
            {formattedTotal}
          </ForecastAmount>
        ) : (
          <Typography
            as="div"
            variant={variant === 'small' ? Typography.Variant.BODY_LARGE_REGULAR : Typography.Variant.HEADING_4_MEDIUM}
          >
            {formattedTotal}
          </Typography>
        )}
      </div>
    </div>
  );
});

export const KpiRuleItem = memo(KpiRuleItemForwardRef);
