import { type TooltipProps } from '@mui/material';
import moment from 'moment';
import { type ReactNode, memo, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

import { type Payment } from '@amalia/core/types';
import { Tooltip, Typography } from '@amalia/design-system/components';
import { type CompanyCustomization } from '@amalia/tenants/companies/types';

export type PaymentTooltipProps = {
  readonly payment: Payment;
  readonly formattedValue: ReactNode;
  readonly companyCustomization: Partial<CompanyCustomization>;
  readonly title: TooltipProps['title'];
  readonly children: TooltipProps['children'];
};

export const PaymentTooltip = memo(function PaymentTooltip({
  title,
  payment,
  formattedValue,
  companyCustomization,
  children,
}: PaymentTooltipProps) {
  const textToDisplay = useMemo(() => {
    switch (true) {
      // Locked but we don't have any info.
      case !payment.paymentPeriodId && !payment.paymentDate && !payment.forecastPaymentDate:
        return (
          <FormattedMessage
            defaultMessage="Not yet released."
            description="Payment not yet released."
          />
        );

      // Locked and unpaid.
      case !payment.paymentPeriodId && !payment.paymentDate && !!payment.forecastPaymentDate:
        return (
          <FormattedMessage
            defaultMessage="{holdAndReleaseDateLabel}: {date}"
            values={{
              holdAndReleaseDateLabel: companyCustomization.holdAndReleaseForecastPaymentDateLabel || (
                <FormattedMessage defaultMessage="Forecast payment date" />
              ),
              date: moment.utc(payment.forecastPaymentDate).format('YYYY-MM-DD'),
            }}
          />
        );

      // Locked and paid.
      case !payment.paymentPeriodId && !!payment.paymentDate:
        return (
          <FormattedMessage
            defaultMessage="{holdAndReleaseDateLabel}: {date}"
            values={{
              holdAndReleaseDateLabel: companyCustomization.holdAndReleasePaymentDateLabel || (
                <FormattedMessage defaultMessage="Payment date" />
              ),
              date: moment.utc(payment.paymentDate).format('YYYY-MM-DD'),
            }}
          />
        );

      // Unlocked.
      case !!payment.paymentPeriodId && !!payment.paymentPeriod:
        return (
          <FormattedMessage
            defaultMessage="Released on period: {periodName}"
            values={{ periodName: payment.paymentPeriod.name }}
          />
        );
      // This should not happen normally.
      default:
        return null;
    }
  }, [payment, companyCustomization]);

  return (
    <Tooltip
      title={title}
      content={
        <div>
          <Typography variant={Typography.Variant.BODY_BASE_MEDIUM}>{formattedValue}</Typography>
          <div>{textToDisplay}</div>
        </div>
      }
    >
      {children}
    </Tooltip>
  );
});
