import { css } from '@emotion/react';
import { memo } from 'react';

import { type FormatsEnum } from '@amalia/data-capture/fields/types';
import { type CurrencySymbolsEnum } from '@amalia/ext/iso-4217';
import { assert } from '@amalia/ext/typescript';

import { formatChartValue } from '../chartValue.formatter';

type LabelProps = {
  readonly value?: number;
  readonly x?: number;
  readonly y?: number;
  readonly width?: number;
  readonly currency: CurrencySymbolsEnum;
  readonly fontSize?: number;
  readonly fontWeight?: number;
  readonly format: FormatsEnum;
  readonly index?: number;
  readonly isRecordActiveByIndex: (index: number) => boolean;
};

export const BarLabel = memo(function Label({
  currency,
  value,
  x,
  y,
  width,
  format,
  fontSize,
  fontWeight,
  index,
  isRecordActiveByIndex,
}: LabelProps) {
  assert(x, 'x is required');
  assert(width, 'width is required');

  const isActive = Number.isFinite(index) && isRecordActiveByIndex(index!);

  return value ? (
    <text
      dx={width / 2}
      dy={-4}
      textAnchor="middle"
      x={x}
      y={y}
      css={(theme) => css`
        font-size: ${fontSize}px;
        font-weight: ${fontWeight};
        fill: ${isActive ? theme.ds.colors.gray[800] : theme.ds.colors.gray[400]};
      `}
    >
      {formatChartValue(value, format, currency)}
    </text>
  ) : null;
});
