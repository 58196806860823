import { useQuery } from '@tanstack/react-query';

import { assert } from '@amalia/ext/typescript';
import { type PeriodFrequencyEnum } from '@amalia/payout-definition/periods/types';
import { PeriodsApiClient } from '@amalia/payout-definition/state';

import { PERIOD_QUERY_KEYS } from './query-keys';

export const usePeriod = (date: string, frequency?: PeriodFrequencyEnum) =>
  useQuery({
    enabled: !!frequency,
    queryKey: [PERIOD_QUERY_KEYS.PERIOD, date, frequency],
    queryFn: () => {
      assert(frequency, 'Frequency must be defined');
      return PeriodsApiClient.getPeriodByDate(date, frequency);
    },
    placeholderData: (pastValue) => pastValue ?? undefined,
  });
