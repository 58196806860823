import { type Theme } from '@emotion/react';
import styled from '@emotion/styled';

interface DividerProps {
  grayShade?: keyof Theme['ds']['colors']['gray'];
}

export const Divider = {
  Horizontal: styled.div<DividerProps>`
    width: 100%;
    height: 1px;
    align-self: stretch;
    background-color: ${({ theme, grayShade }) => theme.ds.colors.gray[grayShade ?? 200]};
    flex: none;
  `,

  Vertical: styled.div<DividerProps>`
    align-self: stretch;
    width: 1px;
    background-color: ${({ theme, grayShade }) => theme.ds.colors.gray[grayShade ?? 200]};
    flex: none;
  `,
} as const;
