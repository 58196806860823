import { useMemo } from 'react';

import { type PayoutAndPerformanceChartStatisticsRecord } from '@amalia/reporting/custom-reports/shared';

export const useAverage = ({
  records,
  isRecordActiveByIndex,
  averageDataKey,
}: {
  records?: PayoutAndPerformanceChartStatisticsRecord[];
  isRecordActiveByIndex: (index: number) => boolean;
  averageDataKey?: string;
}) => {
  const { amount, count } = (records ?? []).reduce(
    (acc, record, index) => {
      // Ignore inactive records.
      if (isRecordActiveByIndex(index)) {
        acc.amount += record.ruleMetricPayment__value?.value ?? 0;
        acc.count += 1;
      }

      return acc;
    },
    { amount: 0, count: 0 },
  );

  const average = count ? amount / count : null;

  return {
    average,
    records: useMemo(
      () =>
        averageDataKey
          ? records?.map((record) => ({
              ...record,
              [averageDataKey]: average,
            }))
          : records,
      [average, averageDataKey, records],
    ),
  };
};
