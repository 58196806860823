import { useCallback, useMemo } from 'react';

import { type PayoutAndPerformanceChartStatisticsRecord } from '@amalia/reporting/custom-reports/shared';

export const useActiveRecords = ({
  records,
  isRecordActiveFn,
}: {
  records?: PayoutAndPerformanceChartStatisticsRecord[];
  isRecordActiveFn?: (record: PayoutAndPerformanceChartStatisticsRecord) => boolean;
}) => {
  // In chart labels, the only data we have is an index so we need to keep track of indexes of inactive records.
  const inactiveRecordsIndexes = useMemo(
    () =>
      // By default all records are active.
      isRecordActiveFn
        ? (records ?? [])
            .map((record, index) => !isRecordActiveFn(record) && index)
            .filter((value): value is number => Number.isFinite(value))
        : [],
    [isRecordActiveFn, records],
  );

  // Encapsulate the logic to check if a record is active by index.
  const isRecordActiveByIndex = useCallback(
    (index: number) => !inactiveRecordsIndexes.includes(index),
    [inactiveRecordsIndexes],
  );

  return {
    isRecordActiveByIndex,
  };
};
