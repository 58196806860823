import { capitalize } from 'lodash';

import { type FormulaKeywordDisplayDetails } from '@amalia/amalia-lang/formula/keywords/components';
import { quotaTypesMessages } from '@amalia/amalia-lang/tokens/intl';
import { TokenType, type Variable } from '@amalia/amalia-lang/tokens/types';
import { type TracingTypes } from '@amalia/core/types';
import { AMALIA_OBJECTS_HIGHLIGHT_COLOR } from '@amalia/ext/mui/theme';
import { ruleTypeMessages } from '@amalia/payout-definition/plans/intl';
import { type Filter, type Relationship, type RuleType } from '@amalia/payout-definition/plans/types';

import { type AmaliaFunctionWithId } from '../components/designer/drawer/drawer.types';
import { type DesignerObjectDetails, DesignerObjectsActions } from '../components/designerNew/designer-new.types';

/**
 * @deprecated
 * We should try and remove any coupling to this configuration in the refactored designer.
 * Looks great, but it is hard to maintain and introduce hasty / unnecessary abstraction & coupling.
 */
export const designerObjectsDetails: Record<TokenType, DesignerObjectDetails> = {
  [TokenType.PLAN]: {
    key: TokenType.PLAN,
    fieldsToSearchIn: ['name'],
    sortField: 'name',
    possibleActions: [
      DesignerObjectsActions.CREATE,
      DesignerObjectsActions.ASSIGN,
      DesignerObjectsActions.DUPLICATE,
      DesignerObjectsActions.EDIT,
      DesignerObjectsActions.DELETE,
    ],
  },
  [TokenType.RULE]: {
    key: TokenType.RULE,
    fieldsToSearchIn: [
      'name',
      'machineName',
      'filterMachineName',
      'formula',
      'filter.name',
      'filter.machineName',
      'filter.object.name',
      'filter.object.machineName',
    ],
    group: {
      field: 'type',
      format: (value) => ruleTypeMessages[value as RuleType],
    },
    sortField: 'machineName',
    possibleActions: [
      DesignerObjectsActions.CREATE,
      DesignerObjectsActions.EDIT,
      DesignerObjectsActions.EDIT_DESCRIPTION,
      DesignerObjectsActions.DELETE_ASSIGNMENT,
      DesignerObjectsActions.DELETE,
      DesignerObjectsActions.DUPLICATE,
    ],
  },
  [TokenType.FILTER]: {
    key: TokenType.FILTER,
    fieldsToSearchIn: ['name', 'machineName', 'condition', 'object.name', 'object.machineName'],
    group: {
      field: (f) => f.object?.name || f.virtualObjectMachineName,
    },
    sortField: 'machineName',
    onCopy: (object: Filter) => `filter.${object.machineName}`,
    possibleActions: [
      DesignerObjectsActions.CREATE,
      DesignerObjectsActions.EDIT,
      DesignerObjectsActions.EDIT_ACHIEVED,
      DesignerObjectsActions.FORECAST,
      DesignerObjectsActions.REMOVE_FROM_RULE,
      DesignerObjectsActions.DELETE,
      DesignerObjectsActions.DUPLICATE,
    ],
  },
  [TokenType.VARIABLE]: {
    key: TokenType.VARIABLE,
    fieldsToSearchIn: ['name', 'machineName', 'formula'],
    group: {
      field: (variable) => variable?.planId || 'Global',
    },
    sortField: 'machineName',
    onCopy: (object: Variable) => `statement.${object.machineName}`,
    possibleActions: [
      DesignerObjectsActions.CREATE,
      DesignerObjectsActions.EDIT,
      DesignerObjectsActions.EDIT_ACHIEVED,
      DesignerObjectsActions.FORECAST,
      DesignerObjectsActions.EDIT_DESCRIPTION,
      DesignerObjectsActions.REMOVE_FROM_RULE,
      DesignerObjectsActions.DELETE,
      DesignerObjectsActions.DUPLICATE,
    ],
  },
  [TokenType.FIELD]: {
    key: TokenType.FIELD,
    fieldsToSearchIn: ['name', 'machineName', 'formula', 'object.name', 'object.machineName'],
    group: {
      field: 'object.name',
    },
    sortField: 'machineName',
    onCopy: (object: Variable) => `${object.object!.machineName}.${object.machineName}`,
    possibleActions: [
      DesignerObjectsActions.CREATE,
      DesignerObjectsActions.EDIT,
      DesignerObjectsActions.EDIT_ACHIEVED,
      DesignerObjectsActions.FORECAST,
      DesignerObjectsActions.EDIT_DESCRIPTION,
      DesignerObjectsActions.DELETE,
      DesignerObjectsActions.DUPLICATE,
    ],
  },
  [TokenType.PROPERTY]: {
    key: TokenType.PROPERTY,
    fieldsToSearchIn: ['name', 'definition.name', '$comment', 'description'],
    sortField: 'name',
    group: {
      field: 'definition.name',
    },
    onCopy: (object: TracingTypes.Property) => `${object.definition.machineName}.${object.id}`,
    possibleActions: [],
  },
  [TokenType.VIRTUAL_PROPERTY]: {
    key: TokenType.VIRTUAL_PROPERTY,
    fieldsToSearchIn: ['name', 'definition.name', '$comment', 'description'],
    sortField: 'name',
    group: {
      field: 'definition.name',
    },
    onCopy: (object: TracingTypes.Property) => `${object.definition.machineName}.${object.id}`,
    possibleActions: [],
  },
  [TokenType.LINK]: {
    key: TokenType.LINK,
    fieldsToSearchIn: [
      'name',
      'label',
      'fromDefinitionMachineName',
      'toDefinitionMachineName',
      'fromDefinitionField',
      'toDefinitionField',
      'condition',
    ],
    sortField: 'name',
    onCopy: (object: Relationship) => `${object.fromDefinitionMachineName}.${object.name}`,
    possibleActions: [
      DesignerObjectsActions.CREATE,
      DesignerObjectsActions.EDIT,
      DesignerObjectsActions.DELETE,
      DesignerObjectsActions.DUPLICATE,
    ],
    group: {
      field: 'fromDefinitionMachineName',
    },
  },
  [TokenType.QUOTA]: {
    key: TokenType.QUOTA,
    fieldsToSearchIn: ['name', 'machineName'],
    sortField: 'machineName',
    possibleActions: [
      DesignerObjectsActions.CREATE,
      DesignerObjectsActions.EDIT,
      DesignerObjectsActions.REMOVE_FROM_RULE,
      DesignerObjectsActions.DELETE,
      DesignerObjectsActions.DUPLICATE,
    ],
    onCopy: (object: Variable) => `${object.type}.${object.machineName}`,
    group: {
      field: 'type',
      format: (value: string) =>
        value in quotaTypesMessages ? quotaTypesMessages[value as keyof typeof quotaTypesMessages] : value,
    },
  },
  [TokenType.FUNCTION]: {
    key: TokenType.FUNCTION,
    textColor: AMALIA_OBJECTS_HIGHLIGHT_COLOR.function,
    fieldsToSearchIn: ['name'],
    sortField: 'name',
    onCopy: (object: AmaliaFunctionWithId) => `${object.name}`,
    getDescription: (object: AmaliaFunctionWithId) => `${object.description}`,
    possibleActions: [DesignerObjectsActions.OPEN],
    group: {
      field: 'category',
    },
  },
  [TokenType.KEYWORD]: {
    key: TokenType.KEYWORD,
    fieldsToSearchIn: ['name'],
    sortField: 'name',
    onCopy: (object: FormulaKeywordDisplayDetails) => `${object.formula}`,
    getDescription: (object: FormulaKeywordDisplayDetails) => object.description,
    possibleActions: [DesignerObjectsActions.OPEN],
    group: {
      field: (object: FormulaKeywordDisplayDetails) => object.id.split('.')[0],
      format: (value: string) => capitalize(value),
    },
  },
  // TEMPORARY
  [TokenType.OBJECT]: {
    key: TokenType.OBJECT,
    fieldsToSearchIn: ['name', 'definition.name', '$comment', 'description'],
    sortField: 'name',
    group: {
      field: 'definition.name',
    },
    onCopy: (object: TracingTypes.Property) => `${object.definition.machineName}.${object.id}`,
    possibleActions: [],
  },
};
