import { css } from '@emotion/react';

import { assert } from '@amalia/ext/typescript';

export type YAxisTickProps<TValue> = {
  readonly x?: number;
  readonly y?: number;
  readonly payload?: {
    value: TValue;
  };
  readonly tickFormatter?: (value: TValue) => string;
  readonly textAnchor?: string;
  readonly fontSize?: number;
  readonly fontWeight?: number;
};

export const YAxisTick = function YAxisTick<TValue>({
  x,
  y,
  payload,
  tickFormatter,
  textAnchor,
  fontSize,
  fontWeight,
}: YAxisTickProps<TValue>) {
  assert(payload);
  assert(tickFormatter);

  return (
    <text
      dy={4}
      textAnchor={textAnchor}
      x={x}
      y={y}
      css={(theme) => css`
        font-size: ${fontSize}px;
        font-weight: ${fontWeight};
        fill: ${theme.ds.colors.gray[500]};
      `}
    >
      {tickFormatter(payload.value)}
    </text>
  );
};
