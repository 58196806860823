import { memo } from 'react';

import { Group, Stack } from '@amalia/design-system/components';
import { assert } from '@amalia/ext/typescript';
import { useStatementDetailContext } from '@amalia/lib-ui';
import { RuleLayout } from '@amalia/payout-definition/plans/rules/components';
import { type PlanRule } from '@amalia/payout-definition/plans/types';

import { ChartItem } from './ChartItem';

interface ChartsProps {
  readonly ruleDefinition: PlanRule;
}

export const Charts = memo(function Charts({ ruleDefinition }: ChartsProps) {
  const { statement } = useStatementDetailContext();

  assert(statement, 'Statement is required');

  return (
    <RuleLayout.Charts>
      <Group
        align="flex-start"
        gap={24}
        justify="space-between"
      >
        <Stack
          grow
          align="center"
          gap={24}
        >
          {(ruleDefinition.charts || []).map((c) => (
            <ChartItem
              key={c.id}
              chart={c}
              statement={statement}
            />
          ))}
        </Stack>
      </Group>
    </RuleLayout.Charts>
  );
});
