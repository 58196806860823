import { http } from '@amalia/core/http/client';
import { type PaginatedResponse } from '@amalia/core/types';
import {
  type CustomReportFilterOption,
  type CustomReport,
  type CustomReportConfiguration,
  type CustomReportDataSourceConfiguration,
  type CustomReportDataSourceManifest,
  type CustomReportFacetRequest,
  type CustomReportFilterOptionsMap,
  type CustomReportFolder,
  type CustomReportRecordsRequest,
  type CustomReportRow,
  type CustomReportToCreate,
  type CustomReportToPatch,
} from '@amalia/reporting/custom-reports/shared';

// ===================== Custom Report CRUD =====================

const apiEndpoint = '/custom-reports';

const apiEndpointFolders = '/custom-reports-folders';

export class CustomReportsApiClient {
  public static async getCustomReports(): Promise<CustomReport[]> {
    const { data } = await http.get<CustomReport[]>(`${apiEndpoint}/`);
    return data;
  }

  public static async createCustomReport(payload: CustomReportToCreate): Promise<CustomReport> {
    const { data } = await http.post<CustomReport>(`${apiEndpoint}/`, payload);
    return data;
  }

  public static async getCustomReportById(customReportId: CustomReport['id']): Promise<CustomReport> {
    const { data } = await http.get<CustomReport>(`${apiEndpoint}/${customReportId}`);

    return data;
  }

  public static async patchCustomReport({
    customReportId,
    diff,
  }: {
    customReportId: CustomReport['id'];
    diff: CustomReportToPatch;
  }): Promise<CustomReport> {
    const { data } = await http.patch<CustomReport>(`${apiEndpoint}/${customReportId}`, { ...diff });
    return data;
  }

  public static async deleteCustomReport(customReportId: CustomReport['id']): Promise<void> {
    await http.delete(`${apiEndpoint}/${customReportId}`);
  }

  public static async duplicateCustomReport(customReportId: CustomReport['id']): Promise<CustomReport> {
    const { data } = await http.post<CustomReport>(`${apiEndpoint}/clone/${customReportId}`);
    return data;
  }

  // ===================== Custom Report Folders =====================
  public static async getCustomReportsFolders(): Promise<CustomReportFolder[]> {
    const { data } = await http.get<CustomReportFolder[]>(`${apiEndpointFolders}`);
    return data;
  }

  public static async createCustomReportFolder(payload: Pick<CustomReportFolder, 'name'>): Promise<CustomReportFolder> {
    const { data } = await http.post<CustomReportFolder>(`${apiEndpointFolders}`, payload);
    return data;
  }

  public static async patchCustomReportFolder({
    id,
    diff,
  }: {
    id: string;
    diff: Partial<Pick<CustomReportFolder, 'name' | 'parentId' | 'permissions'>>;
  }): Promise<CustomReportFolder> {
    const { data } = await http.patch<CustomReportFolder>(`${apiEndpointFolders}/${id}`, {
      parentId: diff.parentId,
      name: diff.name,
      permissions: diff.permissions,
    });
    return data;
  }

  public static async deleteFolder({ id, keepFiles }: { id: string; keepFiles?: boolean }): Promise<void> {
    await http.delete(`${apiEndpointFolders}/${id}`, { params: { keepFiles } });
  }

  // ===================== Custom Report Content and editor dependencies =====================

  public static async getCustomReportContent(
    customReportId: CustomReport['id'],
    configuration: CustomReportConfiguration | undefined,
    dataSourceConfiguration: CustomReportDataSourceConfiguration | undefined,
    pagination: CustomReportRecordsRequest['pagination'],
  ): Promise<PaginatedResponse<CustomReportRow>> {
    const request: CustomReportRecordsRequest = {
      pagination,
      configuration,
      dataSourceConfiguration,
    };

    const { data } = await http.post<PaginatedResponse<CustomReportRow>>(
      `${apiEndpoint}/${customReportId}/records`,
      request,
    );
    return data;
  }

  public static async getManifests(
    customReportId: CustomReport['id'],
    configuration: CustomReportConfiguration | null,
  ): Promise<Record<string, CustomReportDataSourceManifest>> {
    const { data } = await http.post<Record<string, CustomReportDataSourceManifest>>(
      `${apiEndpoint}/${customReportId}/manifests`,
      {
        configuration,
      },
    );
    return data;
  }

  public static async getFilterOptions(
    customReportId: CustomReport['id'],
    configuration: CustomReportConfiguration | undefined,
    fields: string[],
  ): Promise<CustomReportFilterOptionsMap> {
    const { data } = await http.post<CustomReportFilterOptionsMap>(`${apiEndpoint}/${customReportId}/filters-options`, {
      configuration,
      fields,
    });
    return data;
  }

  public static async getFacet(
    customReportId: CustomReport['id'],
    customReportFacetRequest: CustomReportFacetRequest,
  ): Promise<CustomReportFilterOption[]> {
    const { data } = await http.post<CustomReportFilterOption[]>(
      `${apiEndpoint}/${customReportId}/facet-options`,
      customReportFacetRequest,
    );
    return data;
  }
}
