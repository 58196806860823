import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { memo, type ReactNode, useMemo } from 'react';

import { TracingTypes } from '@amalia/core/types';
import { type AmaliaThemeType, colors } from '@amalia/ext/mui/theme';

const useStyles = makeStyles((theme: AmaliaThemeType) => ({
  root: {
    padding: theme.spacing(0.25),
    minHeight: '3.2rem',
    display: 'inline-flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    position: 'relative',
  },
  tracingArrow: {
    height: theme.spacing(10),
    position: 'absolute',
    top: '100%',
    left: 'calc(50% - 2rem)',
    width: '4rem',
    strokeWidth: 4,
    zIndex: 2,
  },
  link: {
    cursor: 'pointer',
  },
  linkActive: {
    color: theme.palette.primary.main,
    textDecoration: 'underline',
  },
  label: {
    padding: theme.spacing(0.25),
    border: '1px solid transparent',
    borderRadius: theme.shape.borderRadius / 2,
  },

  noBorder: {
    borderColor: 'transparent',
  },

  // Styles according to the type
  [`label_${TracingTypes.TracingLabelType.function}`]: {
    '&:not($noBorder)': {
      borderColor: theme.palette.secondary.main,
    },
    color: theme.palette.secondary.main,
  },
  [`label_${TracingTypes.TracingLabelType.filter}`]: {
    backgroundColor: colors['green-100'],
    color: 'rgba(0, 0, 0, .4)',
  },
  [`label_${TracingTypes.TracingLabelType.property}`]: {
    color: theme.palette.secondary.main,
  },
  [`label_${TracingTypes.TracingLabelType.table}`]: {
    color: theme.palette.link.main,
  },
  [`label_${TracingTypes.TracingLabelType.property}_with_value`]: {
    color: theme.palette.link.main,
  },
  [`label_${TracingTypes.TracingLabelType.other}`]: {},
}));

// All data to link to another TracingBlock
interface TracingLinkProps {
  // Children of this block
  readonly formulaChildren: any[];
  readonly setFormulaChildren: (formulaChildren: any[]) => void;
  // Current node
  readonly nodePart: any;
  // Index of the current node in the current formula
  readonly indexInFormula: number;
  // Index of the opened node in the parent formula
  readonly indexChild: number;
  readonly children: ReactNode;
  readonly functionParameters?: any[];
}

export const TracingLink = function TracingLink({
  formulaChildren,
  setFormulaChildren,
  nodePart,
  indexInFormula,
  indexChild,
  children,
  functionParameters,
}: TracingLinkProps) {
  const classes = useStyles();

  // isActive tells if the current link is opened (has been clicked by the user)
  // It permits to highlight this link
  // We filter each child. The nodeId must be the same, and the same indexInFormula (to not open all related nodes)
  const isActive = useMemo(
    () =>
      !!formulaChildren.filter(
        (child) =>
          child.tracingData.id === nodePart.value?.id &&
          child.index === indexChild + 1 &&
          child.indexInFormula === indexInFormula,
      ).length,
    [nodePart, formulaChildren, indexChild, indexInFormula],
  );

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <div
      className={clsx(classes.link, isActive ? classes.linkActive : null)}
      onClick={(event): void => {
        event.preventDefault();

        // clone to rerender
        const formulaChildrenTmp = [];
        const newIndex = indexChild + 1;

        // If other childs with index equal or superior exist, we remove them
        formulaChildren.forEach((formulaChildTmp) => {
          if (formulaChildTmp.index < newIndex) {
            formulaChildrenTmp.push(formulaChildTmp);
          }
        });

        // We push the new child into the parent formulaChildren
        formulaChildrenTmp.push({
          index: newIndex,
          tracingData: nodePart.value,
          indexInFormula,
          functionParameters,
        });

        setFormulaChildren(formulaChildrenTmp);
      }}
    >
      {children}
    </div>
  );
};

interface TracingLabelProps {
  readonly type: TracingTypes.TracingLabelType;
  readonly children: ReactNode;
  readonly value?: ReactNode;
  readonly bgColor?: string;
}

/**
 * TracingLabel
 * Prints a label of a tracing node
 */
export const TracingLabel = memo(function TracingLabel({ type, children, value, bgColor }: TracingLabelProps) {
  const classes = useStyles();

  let className = classes[`label_${type}`];
  if (type === TracingTypes.TracingLabelType.property && value) {
    className = classes[`label_${type}_with_value`];
  }

  return (
    <div className={classes.root}>
      <div
        className={clsx(classes.label, className, bgColor !== 'transparent' && classes.noBorder)}
        style={{ backgroundColor: bgColor || 'transparent' }}
      >
        {children}
      </div>
      {value}
    </div>
  );
});
