import { last } from 'lodash';
import { useCallback } from 'react';

import { type SelectDropdownProps } from '@amalia/design-system/components';
import { isEnum } from '@amalia/ext/typescript';
import { RelativePeriodKeyword } from '@amalia/payout-definition/periods/types';

import { type PeriodOption, type PeriodValue } from './PeriodSelect.types';

export const usePeriodSelector = ({
  onChange,
}: {
  onChange: SelectDropdownProps<PeriodOption, true, false>['onChange'];
}) => {
  const handleChangeProxy = useCallback(
    (newValues: PeriodValue[]) => {
      if (newValues.length === 0) {
        onChange?.([]);
        return;
      }

      if (isEnum(last(newValues), RelativePeriodKeyword)) {
        onChange?.([last(newValues)!]);
        return;
      }

      onChange?.(newValues.filter((v) => !isEnum(v, RelativePeriodKeyword)));
    },
    [onChange],
  );

  return {
    onChange: handleChangeProxy,
  };
};
